const content = {
  drawing: "Allows you to draw on the on-site user's display",
  screenshot: "Capture a screenshot of the on-site user's camera",
  cad: "Send a CAD model to the on-site user",
  documents: "View a list of documents attached to this room",
  assets: "Upload assets to be shared in the room with other users. This may include PDF documents, images, video, or CAD models.",
  chat: "Send and receive messages from other users",
  tools: "Shows a list of connected devices",
  roomInfo: "The room you're connected to. Click to see additional details as well as a QR code",
  statusPane: "Change log and debug settings for connected users and devices",
  disconnect: "Leave the current room",
  invite: "Invite a user to the room",
  toggleAudio: 'Mute/un-mute local audio',
  toggleVideo: 'Mute/un-mute local video',
  toggleVideoList: {
    content: "Toggle grid versus list view for all connected users",
    position: "right"
  },
  focusVideo: {
    content: "Switches to a grid-view of all connected users",
    position: "right"
  },
  focusUnity: {
    content: "Switches to the Unity player",
    position: "right"
  },
  focusSplitView: {
    content: "Switches to split-view mode to view both the video grid and Unity player",
    position: "right"
  }
}


const tour = Object.keys(content).map( key => {
  if( typeof content[key] === 'object' ){
    return {
      target: `[data-tour=${key}]`,
      ...content[key]
    }
  }

  return {
    target: `[data-tour=${key}]`,
    content: content[key],
    position: 'bottom'
  }
});

export default tour;