import { useSession, useSettings, useRoom } from '@turnaroundfactor/react';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useCallback, useState, useMemo, useRef, createContext } from 'react';
import DrawView from './DrawView';
import Toolbar from './DrawTools';
import Arrow from './Arrow';

import { 
  fetchPerspective,
  submitArrow,
  listArrows
} from './helpers';

export const DrawContext = createContext();

export default function Draw(){
  const { user } = useSession();
  const { settings } = useSettings();
  const { ARFeeds } = settings;
  
  const [peerId, setPeerId] = useState(ARFeeds[0]);
  const { users } = useRoom();
  
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const [view, setView] = useState('video');

  const [perspective, setPerspective] = useState(null);
  const [timestamp, setTimestamp] = useState(null);

  const [arrow, updateArrow]   = useState(null);
  const [arrows, setArrows] = useState([]);
  
  const canvas  = useRef(null);
  const drawing = useRef(null);

  const peer = useMemo(() => {
    return users.find(m => m.uuid === peerId);
  }, [peerId, users]);
  
  function addArrow(event) {
    event?.preventDefault();
    const arr = { 
      uuid: uuidv4(), 
      label: `Arrow ${arrows.length + 1}`, 
      saved: false, 
      version: 1,
      color: '#FFFFFF',
      rotation: 0,
      coords: []
    };

    setView('draw');
    updateArrow(arr);
  }

  const saveArrow = useCallback(() => {
    setArrows(arrows.map(a => {
      if ( a.uuid === arrow.uuid ) return { ...a, saved: true };
      return a;
    }));

    if ( arrow.saved ) {
      submitArrow({
        peer, 
        user, 
        arrow, 
        coords: [],
        perspective,
        timestamp
      });
    } else  {
      const c = canvas.current;
      const img = c.querySelector('img');
      const coords = [ img?.width, img?.height ];
      submitArrow({
        peer, 
        user, 
        arrow, 
        coords,
        perspective,
        timestamp
      });
    }

    setView('video');
    updateArrow(null);

  }, [arrows, peer, user, arrow, perspective, timestamp]);

  const clearArrows = useCallback(() => {
    arrows.forEach(a => {
      peer?.send('HL2_ArrowRemove', {
        "version": 1,
        "arrow_uuid": a.uuid
      });
    });

    setArrows([]);
    setView('video');
  }, [arrows, peer]);


  const populateArrows = useCallback((data) => {
    console.log(`Received arrow list`, data);
    const { arrows: remoteArrows, target_uuid } = data;
    if ( target_uuid !== peerId ) return;
    if ( Array.isArray(remoteArrows) ){
      setArrows(remoteArrows.map(a => ({ ...a, saved: true })));
    }
  }, [peerId]);


  const removeArrow = useCallback(() => {
    if ( arrow.saved ) {
      peer?.send('HL2_ArrowRemove', {
        "version": 1,
        "arrow_uuid": arrow.uuid
      });
    }

    setArrows(arrows.filter(a => a.uuid !== arrow.uuid ));
    updateArrow(null);
    setView('video');
  }, [peer, arrows, arrow]);

  const updateImage = useCallback(() => {
    setLoading(true);
    fetchPerspective({ peer, user }).then(({ image_data, perspective, ...props }) => {
      setLoading(false);
      setPerspective(perspective);
      setTimestamp(props.datetime)
      setImage(image_data);
    });

  }, [peer, user]);


  useEffect(() => {        
    if ( perspective ) {
      peer?.send('HL2_camera_perspectivedrop', {
        "version": 1,
        "requester_uuid": user?.uuid,
        "perspective": perspective
      });
    }

    if ( view === 'video' ) return;
    if ( peer ) updateImage();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, peerId]);

  useEffect(() => {
    if ( peer ) {
      peer.on('ARROW_LIST', (data) => populateArrows(data));
      listArrows({ peer, user });
    }
    
    return _ => {
      peer?.off('ARROW_LIST');
      peer?.off('ARROW_CREATE');
      peer?.off('ARROW_REMOVE');
      peer?.off('ARROW_SELECT');
      peer?.off('CAMERA');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerId, peer]);

  
  useEffect(() => {
    if ( ARFeeds.length > 0 && !ARFeeds.includes(peerId) ) setPeerId(ARFeeds[0]);
  }, [ARFeeds, peerId]);

  


  return (
    <>
      <DrawContext.Provider value={{ arrows, arrow, updateArrow, removeArrow, setPeerId, addArrow, saveArrow, clearArrows, peerId }}>
        <Toolbar />
        <div className="draw-mode-canvas position-relative d-inline-block" ref={canvas}>
          <DrawView peerId={peerId} image={image} view={view} />
          { loading === true && 
            <div className="screen-tool-loader">
              <i className="fa fa-spinner fa-spin fa-fw fa-lg mb-2"></i> 
              <span className="font-italic d-block text-center small">Fetching perspective....</span>
            </div>
          }
          <div className="draw-mode-arrows position-absolute" id="draw_arrow_container" ref={drawing}>
            {loading === false && arrow && !arrow.saved && <Arrow {...arrow} container={drawing.current} /> }
          </div>
        </div>
      </DrawContext.Provider>
    </>
  )
}