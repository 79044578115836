import store from '../data/store'
import { updateSettings } from '@turnaroundfactor/react'

export default function updateARState(peerId, active){
  const { app } = store.getState();
  const { ARFeeds = [] } = app;

  let feeds;
  if ( active ){
    feeds = ARFeeds.includes(peerId) ? ARFeeds : [...ARFeeds, peerId];
    updateSettings({ AREnabled: true, ARFeeds: feeds });
  } else {
    feeds = [...ARFeeds].filter(f => f !== peerId);
    updateSettings({ AREnabled: (feeds.length > 0), ARFeeds: feeds });
  }
}
