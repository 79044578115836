import adapt, { PGP_WORDS } from '@turnaroundfactor/common'
import React, { useEffect, useRef, useState } from 'react'
import CreateRoom from './CreateRoom'
import PGPWordInput from '../../components/PGPWordInput';

export default function RoomSelect({ joinRoom }){
  const [mode, setMode] = useState('main');
  const [ rooms, setRooms ] = useState([]);
  const [ selected, setSelected ] = useState(null);

  const words = useRef([null, null, null, null]);
  async function setWord(value, index){
    words.current[index] = value;
    if( words.current.find(v => v === null) === null ) return;
    const sname = words.current.join("-");
    const room = rooms.find(r => r.shortname === sname );

    if( room ){
      setSelected(room);
      return room;
    }else{
      const result = await adapt.getRoom(sname);

      if( result ){
        setSelected(result);
        return result;
      }
    }
  }

  useEffect(()=>{
    async function getRooms(){
      const daysOffset = (24*60*60*1000) * 1; // 5 days.
      try{
        const r = await adapt.getRooms({
          created_after: new Date().getTime() - daysOffset //21600000
        });
        setRooms(r);
      }catch(e){
        setRooms([])
      }
    }

    getRooms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if( mode === 'create' ){
    return <CreateRoom onSubmit={r => joinRoom(r)} onCancel={_ => setMode('main')} />
  }

  return (
    <div className='d-flex flex-col flex-md-row align-items-center'>
        <button type="button" className="btn btn-dark fa-inline fa-plus" onClick={e => setMode('create')}>Create Session</button>
        <span className="d-block px-3">or</span>
        <div className="flex-grow-1">
          <div className="d-flex">
            <div className="d-flex align-items-center mx-0 mr-2">
              <PGPWordInput onChoose={v => setWord(v, 0)} placeholder={'Enter code'} /> -
              <PGPWordInput onChoose={v => setWord(v, 1)} /> -
              <PGPWordInput onChoose={v => setWord(v, 2)} /> -
              <PGPWordInput onChoose={v => setWord(v, 3)} />
            </div>
            <datalist id="clist">
              { PGP_WORDS ? PGP_WORDS.map(w => <option key={w} value={w.toString().toLowerCase()} />) : false }
            </datalist>
            <button type="button" className="ml-3 btn btn-dark" onClick={e => joinRoom(selected)} disabled={selected === null}>Join</button>
          </div>
        </div>
    </div>
  )
}