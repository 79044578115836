import QRCode from 'qrcode';

export async function generateRoomQR(roomID, size = 120) {
  const url = await QRCode.toDataURL(roomID, {
    width: size,
    color: {
      dark: "#091930",
      light: "#8892a100"
    }
  });

  return url;
}