import { AbortController } from "@azure/abort-controller";
import { BlockBlobClient } from '@azure/storage-blob'
import { Model, assetService, ADAPT_CONFIG } from '@turnaroundfactor/common';
import { Evented } from '@turnaroundfactor/common/src/core/evented';
import store from '../data/store';
import { uploadFile, uploadComplete, updateFile, clearUploads } from '../data/store';
import axios from 'axios';
import logger from './logging';

export function getFileMimeFromURI(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(false);
    };

    reader.onload = () => {
      const string = reader.result;
      const result = string.match(/^data:([A-Za-z/-]+)/);

      // find the mime type from "data:mime-type"
      if (result.length >= 2){
        resolve(result[1]);
      }
      reject(false);
    };
    reader.readAsDataURL(file);
  });
}


class Asset extends Model{
  constructor(props){
    super();
    super.assign(props);
    this.progress = 0;
    this.status = 'pending';
    store.dispatch(uploadFile(this.serialize()));
  }

  // Cancel upload if relevant, remove from store
  async cancel(){
    try{
      this.signal.abort();
      this.signal = null;
    } catch(e){}

    if (this.uuid) { 
      try{
        await axios.delete(assetService('destroy'), { uuid: this.uuid });
      } catch(e) {}
    }

    store.dispatch(uploadComplete(this.serialize()));
  }

  fakeUpload() {
    if ( this.validate() === false ){
      store.dispatch(updateFile(this.serialize()));
      return false;
    }

    console.log({ 
      room: this.roomId, 
      filename: this.filename, 
      mimeType: this.mimeType 
    })

    if ( this.progress === 100 ){
      store.dispatch(uploadComplete(this.serialize()));
      return;
    }

    this.progress += 10;
    store.dispatch(updateFile(this.serialize()));
    setTimeout(_ => this.fakeUpload(), 1000);
  }

  async upload(){

    const valid = await this.validate();
    if ( valid === false ){
      store.dispatch(updateFile(this.serialize()));
      return false;
    }

    let uploadURL;
    let sasToken;

    this.uploading = true;
  
    try{  
      const { data } = await axios.post(assetService('create'), 
        { 
          room: this.roomId, 
          filename: this.filename, 
          mimeType: this.mimeType 
        });
      
      const { uuid, progress, status, type } = data;
      uploadURL = data.uploadURL;
      sasToken  = data.sasToken;

      this.assign({ uuid, progress, status, type });

    } catch(e) {
      logger.error(e);
      this.error = e?.response?.data || 'invalid file type';
      this.status = 'failed';
      store.dispatch(updateFile(this.serialize()));
      return false;
    }

    const file = this.file;
    this.signal = new AbortController();
    
    try {
      const blobClient = new BlockBlobClient(`${uploadURL}?${sasToken}`);
      await blobClient.uploadData(file, {
        metadata: {
          uuid: this.uuid,
          source: 'webui',
        },
        blobHTTPHeaders: {
          blobContentType: this.mimeType
        },
        abortSignal: this.signal.signal,
        onProgress: (event)=> {
          const loaded = event?.loadedBytes;
          const total  = file.size;

          if ( loaded && total ) this.progress = Math.round((loaded * 100) / total);
          store.dispatch(updateFile(this.serialize()));
        }
      });

      this.status = 'processing';
      this.progress = 100;
    } catch(e) {
      this.error = e.message;
      this.status = 'failed';
      logger.error(e);
      store.dispatch(updateFile(this.serialize()));
      this.uploading = false;
      this.cancel();
      return;
    }

    this.signal = null;
    this.uploading = false;
    store.dispatch(uploadComplete(this.serialize()));
    this.trigger('COMPLETE', this);

  }

  async validate() {
    const ext = this.filename.toString().split('.').pop().toLowerCase();
    const allExt = Object.keys(ADAPT_CONFIG.FILE_TYPES).map(k => k.toString().toLowerCase());

    if ( !allExt.includes(ext) ){
      this.error = `invalid file type '${ext}'`;
      this.status = 'failure';
      return false;
    }

    const { mime } = ADAPT_CONFIG.FILE_TYPES[ext];
    // Assign a missing mime type by reading the file quickly into base64 and capturing it.
    if ( this.mimeType === '' || !this.mimeType){ 
      const mime2 = await getFileMimeFromURI(this.file);
      console.log(`Assign missing mime type ${mime2}`);
      if (mime2) this.mimeType = mime2;
    }

    if ( !mime || !mime.includes(this.mimeType) ){
      this.error = `invalid file type '${ext}'`;
      this.status = 'failure';
      return false;
    }

    return true;
  }
  

  serialize() {
    return {
      uid: this.uid,
      uuid: this.uuid,
      filename: this.filename,
      mimeType: this.mimeType,
      userId: this.userId,
      roomId: this.roomId,
      progress: this.progress,
      status: this.status,
      error: this.error
    }
  }

}


class Uploader extends Evented{
 files = [];

 add(props) {
  const asset = new Asset({ 
    uid: new Date().getTime() + this.files.length,
    ...props
  });

  asset.one('COMPLETE', a => {
    this.files = this.files.filter(b => a.uid !== b.uid);
    this.trigger('UPDATE');
  });

  this.files.push(asset);
  asset.upload();
  return asset;
 }

 clear() {
  this.files.forEach(f => f.cancel());
  this.files = [];
  store.dispatch(clearUploads());
  this.trigger('UPDATE');
 }

 remove(auid) {
  const uid = auid instanceof Asset ? auid.uid : auid;
  const asset = this.files.find(a => a.uid === uid);
  if ( asset ) {
    console.debug(`Removing asset ${asset.filename}`);
    asset.cancel();
    this.files = this.files.filter(a => a.uid !== uid);
    this.trigger('UPDATE');
  } else {
    console.debug(`Could not find asset ${uid}`);
  }
 }
}

export { Asset };
const uploader = new Uploader();
export default uploader;
