import { v4 as uuidv4 } from 'uuid';

async function submitArrow(props){
  const {
    peer, 
    user, 
    arrow, 
    coords,
    perspective,
    timestamp
  } = props;

  let attempts = 0;
  const request = {
    "version": 1,
    "arrow_uuid": arrow.uuid,
    "target_uuid": peer.uuid,
    "requester_uuid": user.uuid,
    "arrow_RGBA": arrow.color,
    "arrow_rotation": Math.round(arrow.rotation),
    "arrow_label": arrow.label,
    "position_in_perspective": arrow.coords,
    "perspective_size": coords,
    "perspective_holograms": false,
    "perspective_datetime": timestamp,
    "perspective": perspective
  };

  

  function submit() {
    attempts += 1;
    peer.send('HL2_ArrowCreate', request);
  }

  return new Promise((resolve, reject) => {
    function result(data) {
      const { response } = data;
      if ( response === 'NACK' && attempts >= 5 ) {
        peer.off('ARROW_CREATE');
        reject(data);
      } else if( response === 'NACK' ) {
        peer.one('ARROW_CREATE', result);
        submit();
      } else {
        peer.off('ARROW_CREATE');
        resolve(data);
      }
    }

    peer.one('ARROW_CREATE', result);
    submit();
  });
}


async function fetchPerspective({ peer, user }) {
  return new Promise(( resolve ) => {
    peer.one('CAMERA', (data) => resolve(data));
    peer.send('HL2_camera', {
      "version": 1,
      "target_uuid": peer.uuid,
      "request_uuid": uuidv4(),
      "requester_uuid": user.uuid,
      "format": "JPEG",
      "size": "max",
      "holograms": false,
      "perspective": true
    })
  });
}

async function listArrows({ peer, user }) {
  console.log(`Fetch RemoteArrowList from ${peer.name}`);
  peer.send('RemoteArrowList', {
    "version": 1,
    "target_uuid": peer.uuid,
    "request_uuid": uuidv4(),
    "requester_uuid": user.uuid,
  });
}

export { 
  listArrows,
  submitArrow,
  fetchPerspective
}