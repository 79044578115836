import { useTool } from '@turnaroundfactor/react';
import React from 'react';

function getColor(percent) {
  if( percent <= 10) return 'danger';
  if ( percent > 25 ) return 'success';
  return 'warning';
}

function getIcon(percent) {
  if( percent <= 10) return 'battery-empty';
  if ( percent <= 25 ) return 'battery-quarter';
  if ( percent <= 50 ) return 'battery-half';
  if ( percent <= 75 ) return 'battery-three-quarters';
  return 'battery-full';
}

function Battery({ peer }){

  const { status, data = 0.10 } = useTool(peer, {
    name: "Battery Status",
    usePolling: true
  });

  console.log({data});
  const percent = Math.round(data?.data || 0);
  const color = getColor(percent);
  const icon  = getIcon(percent);


  return (
    <div className={`embedded-node-battery px-3 py-1 bg-light border-bottom`}>
      { status === 'connected' ? 
      <div className={`d-flex flex-row m-0 align-items-center text-${color}`}>
        <i className={`fa fa-fw fa-${icon} fa-lg`}></i>
        <div className="progress flex-grow-1 ml-2" style={{ height: '.5em' }}>
          <div className={`progress-bar bg-${color}`} role="progressbar" style={{ width: `${percent}%` }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <small className="d-block pl-1">{percent}%</small>
      </div>
      : <></>}
    </div>
  )
}

export default Battery;
