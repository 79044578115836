import { forwardRef } from "react"
import IconText from './IconText'

export default forwardRef((props, ref) =>{
  const { 
    label, 
    icon, 
    active, 
    panel = false, 
    badge = null, 
    useTip = true, 
    tour,
    disabled = false
  } = props;


  const { onClick } = props;

  return (
    <button data-tour={tour} 
            ref={ref}
            className={`btn ${active ? 'btn-light bg-white -active' : 'btn-dark'} ${panel === true ? 'panel' : ''} py-3 px-3 px-xl-4 toolbar-button`} 
            data-toggle={ useTip ? 'tooltip' : ''} 
            title={label} onClick={onClick}
            disabled={disabled}>
      <IconText icon={icon} label={label} />
      {badge && <small className="badge badge-danger rounded-circle">{badge}</small>}
    </button>
  );
});

