import React from 'react'

function getInitials(name) {
  if ( name === undefined || name === null ) return "";
  return name.toString().split(/\s/)
      .map(part => part.substring(0, 1).toUpperCase())
      .filter(v => !!v)
      .slice(0, 2)
      .join('')
      .toUpperCase();
}

function generateImage(name, size, color, textColor){
  const initials = getInitials(name);
  const canvas   = document.createElement("canvas");
  const context  = canvas.getContext("2d");
  canvas.height  = size;
  canvas.width   = size;

  context.fillStyle = color;
  context.fillRect(0, 0, size, size);
  context.font = `bold ${size * 0.5}px Helvetica`;
  context.fillStyle = textColor;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(initials, size / 2, (size / 2) + (size * .05));

  return canvas.toDataURL('image/png');
}

const Avatar = React.forwardRef((props, ref) =>{
  const { user, src = null, size = 600, color = '#53a1d1', textColor = 'white', className = "" } = props;
  const { name } = user;
  const source = src || generateImage(name, size, color, textColor);
  return <img src={source} ref={ref} className={`rounded-circle mw-100 ${className} avatar`} alt={name} />
});

export default Avatar;