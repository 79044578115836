import { createRoot } from 'react-dom/client';

import { ADAPT_CONFIG, pspdfService, RTC } from '@turnaroundfactor/common';
import axios from 'axios';
import pack from '../package.json';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/app.scss';
import { isIOSDevice } from './utils/device';

if (isIOSDevice()) {
  let hash = window.location.hash;
  if( hash === '' ) hash = 'dashboard';
  window.location.href = `smeconnect://${hash.replace(/^[#/]+/, '')}`;
}

RTC.configure({
  RTCPeerConnection, 
  RTCSessionDescription
});

// 10 seconds
axios.defaults.timeout = 10000;
window.axios = axios;

console.log(`@adapt/webui version ${pack.version}`);

let pdfRoot;

// Configure domain
if( window.location.hostname === 'localhost' ){
  pdfRoot = 'https://pspdf.smeconnect.dev/';
  ADAPT_CONFIG.DOMAIN = "";
  ADAPT_CONFIG.PSPDF_SERVER = pdfRoot;
}else{
  ADAPT_CONFIG.DOMAIN = window.location.hostname;
  pdfRoot = pspdfService(null);
  ADAPT_CONFIG.PSPDF_SERVER = pdfRoot;
}

// Init PSPDF JS
const pspdfScript = document.createElement('script');
pspdfScript.setAttribute('src', `${pdfRoot}pspdfkit.js`);
document.head.appendChild(pspdfScript);

console.debug(`Loading PSPDF scripts from ${pdfRoot}pspdfkit.js`);


// Wrap with <React.StrictMode> to enable strict mode.
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();