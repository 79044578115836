import React, { useEffect, useState } from 'react'
import { useRoom } from '@turnaroundfactor/react'
import { generateRoomQR } from '../features/callScreen/helpers';

export default function JoinDetail() {
  const { uuid, name } = useRoom();
  const [qr, setQR] = useState(null);

  useEffect(()=>{
    async function makeCode() {
      const url = await generateRoomQR(uuid, 160);
      setQR(url);
    }

    if ( uuid ) makeCode(uuid);
  }, [uuid]);

  return (
    <div className="text-center">
      <div className="p-2 mx-auto">
       { qr && <img src={qr} width="160" height="160" alt="" data-testid="qrCode" /> }
      </div>
      <h6 className="fw-bold font-weight-bold text-primary">{name}</h6>
      <p>
        Scan the QR code above to join the room with your device.
      </p>
    </div>
  )
}