import { useSession, useRoom, useSettings } from '@turnaroundfactor/react';
import React, { useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function Screenshot(){
  const { user } = useSession();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const { users, members } = useRoom();
  const { settings, update: updateSettings } = useSettings();
  const { ARFeeds, activeTool } = settings;
  
  const userId = user.uuid;

  const [peerId, setPeerId] = useState(ARFeeds[0]);

  const peer = useMemo(() => {
    return users.find(m => m.uuid === peerId);
  }, [peerId, users]);
  
  function close(event){
    event?.preventDefault();
    if( activeTool === 'screenshot') {
      updateSettings({ activeTool: null });
    }
  }

  function fetchScreenshot() {
    function display(data){
      setLoading(false);
      setImage( data.image_data );
      peer?.off('CAMERA', display);
    }
    if ( peerId && peer ){
      setLoading(true);
      peer?.one('CAMERA', display);
      peer?.send('HL2_camera', {
        "version": 1,
        "target_uuuid": peer.uuid,
        "request_uuid": uuidv4(),
        "requester_uuid": userId,
        "format": "JPEG",
        "size": "max",
        "holograms": false,
        "perspective": false
      });
    }
  }

  useEffect(()=>{
    fetchScreenshot();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerId, members, userId]);

  const peers = members.filter(p => ARFeeds.includes(p.uuid));

  return (
    <div className="screen-tool screenshot-mode">
      <ul className="nav nav-tabs">
        {peers.map( (p, x) => 
        <li key={p.uuid} className="nav-item">
          <button className={`nav-link ${peerId === p.uuid ? 'active' : ''}`} onClick={e => setPeerId(p.uuid) }>
            <i className="fa fa-fw fa-user mr-1 small"></i> <small>{p.name}</small>
          </button>
        </li> 
        )}
        <li className="ml-auto d-flex flex-col">
          <button type="button" className="btn btn-sm btn-secondary flex-grow-1 px-3" onClick={close}>
            <i className="fa fa-times fa-fw"></i>
          </button>
        </li>
      </ul>
      <div className="d-flex flex-column align-items-center justify-contenet-center p-2 position-relative">
        
        <div className="btn-group ml-auto mb-1">
          <a href={`data:image/png;base64,${image}`} download={`Screenshot_${uuidv4()}`} className={`btn btn-dark ${loading === true ? 'disabled' : ''}`}>
            <i className="fa fa-fw fa-download mr-1"></i> Download
          </a>
          <button type="button" className="btn btn-primary" onClick={e => fetchScreenshot(peerId)} disabled={loading}>
          <i className="fa fa-fw fa-sync mr-1"></i> Refresh
          </button>
        </div>
        { loading === true && 
          <div className="screen-tool-loader text-white">
            <i className="fa fa-spinner fa-spin fa-fw fa-lg mb-2"></i> 
            <span className="font-italic d-block text-center small">Fetching screenshot....</span>
          </div>
        }
        
        <div className="screenshot-img bg-black w-100 text-center">
          { image && <img src={`data:image/png;base64,${image}`} alt="Screenshot from OnSite User" className="mw-100" /> } 
        </div>
      </div>
    </div>
  )
}