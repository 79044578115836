import React from 'react';

export default function IconText({ icon, label, brand = false, showLabel }) {
  const labelProps = showLabel === true ? {} : { className: "sr-only" }
  const klass = brand === true ? 'fab' : 'fa'
  return (
    <>
      <i className={`${klass} fa-fw ${icon}`}></i><span {...labelProps}>{label}</span>
    </>
  )
}
