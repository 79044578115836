import { useRef, useState, useContext } from 'react'
import { makeMoveable, Draggable, Rotatable } from "react-moveable";
import { flushSync } from "react-dom";
import { DrawContext } from './Draw';

const Moveable = makeMoveable([ Draggable, Rotatable ]);

function getRotation(node) {
  const styles = window.getComputedStyle(node);
  const matrix = styles.getPropertyValue('-webkit-transform') || 
                 styles.getPropertyValue('-moz-transform') ||
                 styles.getPropertyValue('-ms-transform') ||
                 styles.getPropertyValue('-o-transform') ||
                 styles.getPropertyValue('transform');

  const values = matrix.split('(')[1].split(')')[0].split(',');
  const a = values[0];
  const b = values[1];
  return Math.round(Math.atan2(b, a) * (180/Math.PI));
}

export default function Arrow({ color, label, container }) {
  const node = useRef(null);
  const anchor  = useRef(null);
  const arr  = useRef(null);

  const { updateArrow, arrow } = useContext(DrawContext);

  function updateCoords(data) {
    const { moveable } = data;
    const rect = moveable.getRect();
    const y = rect.top + rect.height;
    const x = rect.left + (rect.width / 2);

    // const image = document.getElementById('draw_arrow_container');
    // const p = image.getBoundingClientRect();
    // const a = anchor.current.getBoundingClientRect();
    const r = getRotation(node.current);
    console.log({ rect, r });
    // console.log({ coords: { left: a.left - p.left, top: a.top - p.top, x, y }  });
    // updateArrow({ 
    //   ...arrow,
    //   rotation: r, 
    //   coords: [a.left - p.left, a.top - p.top]
    // });

    updateArrow({
      ...arrow,
      rotation: 0,//Math.round(rect.rotation),
      coords: [x, y]
    });
  }
  return (
    <>
      <div className="draw-arrow d-inline-block position-relative text-center" ref={node}>
        <div className="draw-arrow_inputs">
          <small className="text-white text-center d-block">{label || '\u00A0'}</small>
        </div>
        <div className="draw-arrow_image position-relative" ref={arr} >
          <i className="fa fa-fw fa-4x fa-chevron-down" style={{ color }}></i>
        </div>
        <div className="arrow-anchor" ref={anchor}></div>
      </div>
      <Moveable
        flushSync={flushSync}
        target={node}
        draggable={true}
        scalable={false}
        keepRatio={true}
        rotatable={true}
        transformOrigin="bottom center"
        viewContainer={container}
        dragContainer={container}
        dragTarget={arr}
        useAccuratePosition={true}
        onRenderEnd={updateCoords}
        onDragEnd={(props) => {
          const { moveable } = props;
          console.log(moveable.getRect());
        }}
        onDrag={(props) => {
          //console.log(props);
          const { transform } = props;
          node.current.style.transform = transform;
        }}
        onRotate={({ transform }) => {
          node.current.style.transform = transform;
        }}
      />
    </>
  )
}