import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';

import useUploads from '../../hooks/useUploads';

function Asset({ uid, progress, filename, mimeType, error, status }){
  const { complete, cancel} = useUploads();

  function completeOrCancel() {
    if (['failed', 'complete'].includes(status)) {
      complete({ uid });
      return;
    } else {
      console.log(`Cancelling upload for ${filename} [${uid}]`);
      cancel({ uid });
    }
  }
  
  return (
    <li className="d-flex flex-column mb-1">
      <div className="d-flex align-items-center small">
        {error && <i className="fa fa-fw fa-exclamation-triangle text-danger mr-1" title={error}></i>}
        <span className={`text-truncate text-nowrap d-block asset-filename py-1 ${error ? 'text-danger' : ''}`}>{filename}</span>
        <button type="button" className="btn btn-sm p" onClick={completeOrCancel}>
          <i className="fa fa-times-circle"></i>
        </button>
      </div>
      {status !== 'failure' && 
      <div className="progress w-100" style={{ height: '5px' }}>
        <div className="progress-bar bg-success" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`}}></div>
      </div>}
    </li>
  )
}

export default function Uploads(){
  const [open, setOpen] = useState(true);
  const { files } = useUploads();
  
  useEffect(() => {
    setOpen(files.length > 0);
  }, [files]);

  const assets = [...files].sort((a1, a2) => {
    if( a1.uid > a2.uid ) return 1;
    if( a1.uid < a2.uid ) return -1;
    return 0;
  });

  return (
    <Collapse in={open}>
      <div>
        <div id="assetUpload" className="p-3 border rounded-sm mb-2">
          <h5 className="text-muted small text-uppercase">
            <small className="font-weight-bold">Uploading</small>
            <i className="fa fa-spinner fa-spin fa-fw ml-2"></i>
          </h5>
          <ul className="list-unstyled">
            {assets.map((file, x) => <Asset key={x} {...file} />)}
          </ul>
        </div>
      </div>
    </Collapse>
  )
}