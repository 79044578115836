import { useRoom } from '@turnaroundfactor/react';
import React from 'react';

import Battery from '../../components/tools/Battery';
import Caliper from '../../components/tools/Caliper';
import GenericVideo from '../../components/tools/GenericVideo';
import MultiMeter from '../../components/tools/MultiMeter';

const mapping = {
  'DMM-v1': MultiMeter,
  'Boroscope-v1': GenericVideo,
  'OpticalMicrometer-v1': Caliper,
  'ScreenCapture-v1': GenericVideo,
  'Microscope-v1': GenericVideo,
  'AreaCamera-v1': GenericVideo,
  'GenericCamera-v1': GenericVideo
};

const batteryID = 'BB2590-v1';


export default function Tools(props){
  const { tools, nodes } = useRoom();

  function getElement(tool){
    const memb = tool.member || {};
    if( mapping[memb.user_class] ){
      return React.createElement(mapping[memb.user_class], { key: tool.uuid, peer: tool } );
    }else{
      return '';
    }
  }

  function batteryTool(n) {
    return toolsForNode(n).find(t => (t.member || {}).user_class === batteryID);
  }

  function toolsForNode(n) {
    return tools.filter(t => t.parent_uuid === n.uuid || t.member?.parent_uuid === n.uuid );
  }

  return (
    <section id='tool_list'>
      <div className="pb-2 mb-3" id="embedded_node_list">
        { nodes.length <= 0 && 
          <h5 className="mb-0 pt-3 px-3 text-muted small font-weight-bold text-uppercase">No nodes currently in the room.</h5>
        }
        { nodes.map(n => 
        <div className="embedded-node pb-3" key={n.uuid}>
          <h5 className="mb-0 small text-white bg-primary font-weight-bold text-uppercase border-bottom py-3 pl-3 pr-2 d-flex" data-toggle="collapse" data-target={`#tools_${n.uuid}`} role="button">
            <span className="d-block text-truncate">
              {n.name} <span className='d-inline-block pl-1'>( {toolsForNode(n).length} )</span>
            </span>
            <i className="fa fa-fw fa-caret-down caret ml-auto"></i>
          </h5>
          {batteryTool(n) && <Battery peer={batteryTool(n)} />}
          <div className="collapse show" id={`tools_${n.uuid}`}>
            { toolsForNode(n).map(t => getElement(t)) }
          </div>
        </div>
        )}
      </div>
    </section>
  )
}