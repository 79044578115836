import { GoogleLogin } from '@react-oauth/google'
import adapt from '@turnaroundfactor/common'
import { useSession, useSettings } from '@turnaroundfactor/react'
import React, { useState, useRef } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Panel from '../../components/Panel'
import { useCACAuth, useGoogleAuth } from './helpers'
import EmailLogin from './Email';

function Login(){
  const { settings, update: updateSettings } = useSettings();
  const { login, user: currentUser } = useSession();
  const [mode, setMode] = useState('default');

  const cacMessage = useRef(null);

  const { prevRoom, signedIn } = settings;

  async function joinPrevRoom(id){
    const room = await adapt.getRoom(id);
    if( room ){
      updateSettings({
        room: { uuid: room.uuid, name: room.name },
        prevRoom: null
      });

      navigate(`/${room.shortname}`);
    }
  }

  function loginSuccess(user){
    if( user === false ) return;
    login(user.serialize());

    if( prevRoom ) return joinPrevRoom(prevRoom);
    navigate(`/`);
  }

  const navigate = useNavigate();
  const { 
    active: googleActive, 
    error: googleError, 
    handler: googleHandler 
  } = useGoogleAuth(loginSuccess);
  
  const { 
    active: cacActive, 
    error: cacError, 
    handler: cacHandler 
  } = useCACAuth(loginSuccess, (msg) => {
    cacMessage.current.innerText = msg
    cacMessage.current.classList.add('py-2');
  });

  const loggingIn = googleActive || cacActive;
  
  if( currentUser && signedIn ){
    return <Navigate to="/" />
  }

  return (
    <div id="connection_main">
      <Panel>
        <div className="text-center" data-testid="login">
          <h2 className="h1 text-primary font-weight-light">Welcome</h2>
          { mode === 'default' && 
          <p className="mb-3">
            { loggingIn === true ? "Signing In...." : "Please choose a login method below."}
          </p> }
          { loggingIn === true ? <div className="py-2"><i className="fa fa-lg fa-fw fa-spinner fa-spin"></i></div> : 
          <div className="d-flex flex-column justify-content-center mx-auto justify-content-center" style={{ maxWidth: '300px' }}>
            { mode === 'default' && <>
            <GoogleLogin 
              onSuccess={googleHandler} 
              onError={googleHandler} 
              //useOneTap={true}
              //auto_select={true}
              ux_mode="popup"
              width="300"
              size="large"
              text="Sign In With Google"
              theme="outline"
              containerProps={{ allow: "identity-credentials-get" }}
              use_fedcm_for_prompt
            />
            <button type="button" className="btn btn-primary my-2" onClick={cacHandler}>
              <i className="fa fa-fw fa-id-card-alt mr-2"></i> CAC Login
            </button>
            <button type="button" className="btn btn-secondary mb-3" onClick={e => setMode('email')}>
              <i className="fa fa-fw fa-envelope mr-2"></i> Email Login
            </button>
            </> }
          </div> }
          { mode === 'email' && <EmailLogin back={e => setMode('default')} onSuccess={loginSuccess} />}
          { loggingIn === false && <>
          { googleError && <p className="text-danger py-2 text-center">{googleError}</p> }
          { cacError && <p className="text-danger py-2 text-center">{cacError}</p> } 
          </>}
          { !cacError && <p ref={cacMessage} className="text-center small mb-0 text-muted"></p> }
        </div>
      </Panel>
    </div>
  )
}

export default Login;