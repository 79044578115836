import { GoogleOAuthProvider } from '@react-oauth/google';
import adapt, { presenceService } from '@turnaroundfactor/common';
import { useSession, useSettings } from '@turnaroundfactor/react';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Joyride from 'react-joyride';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Preload from './components/Preload';
import store from './data/store';
import tourSteps from './data/tour';
import Dashboard from './features/dashboard';
import Login from './features/login';
import Room from './features/room';
import './styles/app.scss';
import getAppURL from './utils/getAppURL';
import logger from './utils/logging';
import onlineState from './utils/onlineState';
import localStore from './utils/localStore';

let sessionTimer;

const googleClientId = '688066647283-167n0q4ti4pn442j853rk4s3erclum01.apps.googleusercontent.com';


// function storeState(data){
//   const { 
//     audioEnabled, 
//     videoEnabled, 
//     logLevel,
//   } = data || {}

//   window.localStorage.setItem('config', JSON.stringify({
//     audioEnabled, 
//     videoEnabled,
//     logLevel,
//   }));
// }

const storedConfig = localStore.get();

adapt.addLogger(logger);

const initialState = {
  activeTool: null,
  activeSidebar: null,
  sidebar: false,
  env: process.env.REACT_APP_BRANCHNAME,
  runTour: false,
  onlineState: 'online',
  pinned: null,
  ARFeeds: [],
  audioEnabled: true,
  videoEnabled: true,
  mediaAllowed: true,
  videoDevice: null,
  audioDevice: null,
  lastMessageCheck: new Date().toLocaleString(),
  ...storedConfig
}

function App() {
  const { settings, update: updateSettings } = useSettings();
  const { 
    logout,
    restore,
    isRestoring
  } = useSession();

  const { runTour, logLevel } = settings;
  const tourHelpers = useRef(null);

  adapt.logger.level = logLevel;
  window.adapt = adapt;

  function tourCallback(data){
    const helpers = tourHelpers.current;
  
    if( data && data.action === 'close' ){
      updateSettings({ runTour: false });
      helpers && helpers.reset && helpers.reset(true);
    }
  }

  useLayoutEffect(()=>{
    console.log('Setting initial state from localStorage.', initialState);
    updateSettings(initialState);

    logger.level = logLevel;
    adapt.on('LOGOUT', async _ => {
      await adapt.logout();
      logout();
      window.location.href = getAppURL();
    });

    restore({
      type: "RemoteExpert",
      user_class: "WebUI"
    });

    sessionTimer = setTimeout(_ =>{
      // TODO: Actually make session catch and restore.  For now use 6 hrs. 
      adapt.trigger('LOGOUT'); 
    }, 21600000);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{

    const leaveRoom = _ => {
      if( adapt.room && (adapt.room.connected || adapt.room.active) ){
        fetch(presenceService(presenceService('room_depart')), {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }, 
          body: adapt.room.uuid, 
          keepalive: true
        });
      }

      if( adapt.log && adapt.log.flush ){
        try{
          adapt.log.flush();
        }catch(e){}
      }
      if( sessionTimer ) clearTimeout(sessionTimer);
    }    

    window.addEventListener('beforeunload', leaveRoom);
    window.addEventListener('offline', onlineState);
    window.addEventListener('online', onlineState);
    
    return _ => {
      if( sessionTimer ) clearTimeout(sessionTimer);
      window.removeEventListener('beforeunload', leaveRoom);
      window.removeEventListener('offline', onlineState);
      window.removeEventListener('online', onlineState);
    }
  }, []);
  
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      { isRestoring === true ? <Preload /> : 
      <HashRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path=":roomId" element={<Room />} />
        </Routes>
      </HashRouter> }
      <ToastContainer autoClose={false} newestOnTop={false} />
      <Joyride 
        steps={tourSteps} 
        run={runTour} 
        continuous={true}
        callback={tourCallback}
        locale={{
          last: 'Close'
        }}
        getHelpers={h => tourHelpers.current = h}
        styles={{
          options: {
            primaryColor: '#333'
          },
          tooltip: {
            fontSize: "1rem"
          },
          tooltipContent:{
            padding: "1rem .5rem",
            fontSize: "1rem"
          },
          tooltipContainer: {
            textAlign: 'left'
          },
          tooltipFooter: {
            marginTop: 0,
            fontSize: '1rem'
          }
        }}
      />
    </GoogleOAuthProvider>
  );
}

function Root() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}


export default Root;

