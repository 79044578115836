import { PGP_WORDS } from '@turnaroundfactor/common'
import React, { useRef } from 'react'

export default function PGPWordInput({ onChoose, ...other }){

  const inpt = useRef(null);

  function checkWord(event){
    if( inpt.current.value.length < 3 ) return;
    const str = inpt.current.value;
    const found = PGP_WORDS.filter(w => w.toLowerCase().startsWith(str.toLowerCase()));
    if( found.length === 1 ){
      event?.preventDefault();
      const real = found.toString().toLowerCase();
      inpt.current.value = real;
      inpt.current.blur();
      onChoose(real);
    }
  }

  return (
    <div className="col-3 px-1 d-flex align-items-center">
      <input type="text" size="12" className="form-control" data-testid="input" onKeyUp={checkWord} ref={inpt} list="clist" {...other} />
    </div>
  )
}