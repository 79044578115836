import { Howl } from 'howler';

function getAudioURL(file) {
  return `${process.env.PUBLIC_URL}/assets/${file}`;
}

const defaults = {};

export const enterRoom = new Howl({ 
  src: [ getAudioURL('room_enter.mp3') ],
  ...defaults
});

export const leaveRoom = new Howl({ 
  src: [ getAudioURL('room_leave.mp3') ],
  ...defaults
});

export const roomRequest = new Howl({ 
  src: [ getAudioURL('room_request.mp3') ],
  ...defaults
});