import { useTool } from '@turnaroundfactor/react';
import Chart from 'chart.js/auto';
import { useEffect, useRef, useState } from 'react';
import Tool from '../Tool';

function MultiMeter({ peer }){
  const [settingsView, setSettingsView] = useState(false);
  const [mode, setMode] = useState('value');
  const [win, setWin] = useState("1000");
  
  const modeRef  = useRef(null);
  const winRef   = useRef(null);
  const wrap     = useRef(null);
  const chart    = useRef(null);
  const canvas   = useRef(null);
  const points   = useRef(null);

  const { status, setPollConfig, data = 0.10 } = useTool(peer, {
    name: "Digital MultiMeter"
  });

  const settings = [
    { icon: 'fa-cog', action: swapSettings }
  ];

  function generateLabels() {
    const len = points.current ? points.current.length : 0;
    return Array.from({ length: len }).fill(data?.unit || 'value');
  }

  function updateChart(){
    if ( !points?.current || chart.current === null || !wrap.current ) return;
    chart.current.data.datasets[0].data = points.current;
    chart.current.data.labels = generateLabels();

    const min = Math.min(...points.current) - 10;
    const max = Math.min(...points.current) + 10;

    chart.current.options.scales.y.min = min;
    chart.current.options.scales.y.max = max;

    try{
      chart.current.update();
    }catch(e){
      // Noop
    }
  }

  useEffect(() => {
    if ( status === 'connected' && chart.current === null ){
      const width = wrap.current.clientWidth;
      wrap.current.style.width = `${width}px`;

      chart.current = new Chart(canvas.current, {
        type: 'line',
        data: {
          labels: generateLabels(),
          datasets: [{
            fill: false,
            data: points.current,
            borderColor: '#184891',
            tension: 0
          }]
        },
        options: {
          animation: false,
          interaction: {
            mode: 'index',
            intersect: false
          },
          devicePixelRatio: 4,
          onResize: function(c, size){
            if ( wrap.current ){
              const ht = size.height < 80 ? 80 : size.height;
              wrap.current.style.width  = `${size.width}px`;
              wrap.current.style.height = `${ht}px`;
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: { mode: 'index' }
          },
          scales: {
            x: { 
              ticks: { display: false }
            },
            y: { 
              ticks: { display: false }
            }
          }
        }
      });

    }

    const ctx = chart.current;

    return _ => {
      if ( ctx ) ctx.destroy();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if ( data && data.data && !isNaN(data.data) ){
      const pts = points.current || Array(9).fill(data.data, 0);
      
      if( pts.length >= 10 ) {
        pts.shift();
      }
    
      pts.push(data.data);
      points.current = pts;
      updateChart();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  useEffect(() => {
    points.current = [];
    updateChart();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.unit])

  function swapSettings(e){
    e.preventDefault();
    setSettingsView(!settingsView);
  }

  function updateSettings(){
    setSettingsView(false);
    setPollConfig({
      window: winRef.current.value,
      mode:   modeRef.current.value
    })
  }

  return (
    <Tool title={peer.name} settings={settings} uuid={peer.uuid}>
      { status === 'connected' ? <>
      <figure className={`${settingsView === true ? 'd-none' : 'd-flex'} flex-column m-0 pt-2`}>
        <div className="bg-light w-100 dmm-chart-wrap" ref={wrap}>
          <canvas width="100%" className="dmm-chart w-100 border" height="56" ref={canvas}></canvas>
        </div>
        <figcaption className="m-0 px-1 py-3 text-center">
          <span className="text-monospace d-block h5 mb-0">{data?.formatted}</span> 
          <span className="d-block">{data?.unit}</span>
        </figcaption>
      </figure>
      <div className={`tool-settings p-2 ${settingsView === true ? 'd-block' : 'd-none'}`}>
        <div className="input-group input-group-sm mb-1">
          <span className="input-group-prepend w-25 small">
            <span className="input-group-text w-100">Mode</span>
          </span>
          <select className="form-control" ref={modeRef} value={mode} onChange={e => setMode(e.target.value)}>
            <option value="value">Value</option>
            <option value="median">Median</option>
            <option value="max">Maximum</option>
            <option value="min">Minimum</option>
            <option value="average">Average</option>
          </select>
        </div>
        <div className="input-group input-group-sm mb-1">
          <span className="input-group-prepend w-25 small">
            <span className="input-group-text w-100">Window</span>
          </span>
          <select className="form-control" ref={winRef} value={win} onChange={e => setWin(e.target.value)}>
            <option value="1000">1s</option>
            <option value="30000">30s</option>
            <option value="60000">1m</option>
            <option value="300000">5m</option>
            <option value="3600000">1hr</option>
          </select>
        </div>
        <button type="button" className='btn btn-dark d-block w-100 btn-sm' onClick={updateSettings}>Update</button>
      </div>
      </>
      : 
      <div className="p-4 text-center tool" id="multimeter_main">
        <i className="fa fa-spinner fa-spin fa-2x text-muted"></i>
        <span className="text-muted font-italic d-block text-center small mt-1">
          { status === 'pending' ? "Awaiting data..." : "Connecting..." }
        </span>
      </div>}
    </Tool>
  )
}

export default MultiMeter
