import React from 'react'
import Avatar from '../../components/Avatar';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

export default function UserImage({ user }){
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{user.name}</Tooltip>}>
    {({ ref, ...triggerHandler }) => (
      <Button variant="link" {...triggerHandler} className="p-0">
        <Avatar size={32} user={user} ref={ref} />
      </Button>
    )}
    </OverlayTrigger>
  );
}