export default async function getMediaDevices() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const result  =  {
    audio: [],
    video: []
  };
  devices.forEach(mediaDevice => {
    if (mediaDevice.kind === 'videoinput') {
      result.video.push({
        id: mediaDevice.deviceId,
        label: mediaDevice.label
      });
    } else {
      result.audio.push({
        id: mediaDevice.deviceId,
        label: mediaDevice.label
      });
    }
  });

  return result;
}