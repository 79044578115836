import { useContext, useMemo } from 'react'
import { useSettings, useRoom } from '@turnaroundfactor/react'
import { NavDropdown, Nav, Navbar, InputGroup, FormControl } from 'react-bootstrap'
import { DrawContext } from './Draw'

export default function Toolbar() {

  const { 
    clearArrows, 
    addArrow, 
    arrows,
    saveArrow,
    updateArrow,
    peerId, 
    setPeerId,
    arrow,
    removeArrow
  } = useContext(DrawContext);

  const { settings } = useSettings();
  const { ARFeeds } = settings;
  const { users } = useRoom();
  const peers = users.filter(u => ARFeeds.includes(u.uuid));

  const peer = useMemo(() => {
    return users.find(p => p.uuid === peerId);
  }, [peerId, users]);

  function selectArrow(a) {
    if (a.saved) {
      console.log(`Ping arrow ${a.uuid}`);
      peer?.send('PingRemoteArrow', {
        "uuid": a.uuid,
        "target_uuid": peer?.uuid,
      });
    }
    console.log({ a });
    updateArrow(a);
  }

  const title = <><i className="fa fa-fw fa-tablet-alt mr-2"></i> <span className="draw-toolbar-selected-peer">{peer?.name}</span></>
  const arrowTitle = `Selected Arrow: ${arrow ? arrow.label || 'New Arrow' : 'None'}`
  
  
  return (
    <Navbar bg="white" variant="light" className="screen-tool-toolbar draw-toolbar text-dark small">
      <Nav className="col-md-4">
        <NavDropdown title={title}>
          <NavDropdown.Header>On-Site Users</NavDropdown.Header>
          <NavDropdown.Divider />
          {peers.map(p => <NavDropdown.Item key={p.uuid} className="small" onClick={e => setPeerId(p.uuid)}>{p.name}</NavDropdown.Item> )}
        </NavDropdown>
      </Nav>
      <Nav className="align-items-center col-md-4">
        <NavDropdown title={arrowTitle} className="mr-4">
          { arrows.map(a => 
          <NavDropdown.Item key={a.uuid} onClick={e => selectArrow(a)}>{a.label} {a.saved === false && <small className="text-muted">(new)</small> }</NavDropdown.Item>) }
          <NavDropdown.Item onClick={addArrow} disabled={arrow && !arrow.saved}>
            <small><i className="fa fa-fw fa-plus"></i> Draw a new arrow</small>
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
      { arrow && 
        <div className="d-flex ml-auto">
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>Arrow</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl size="18" defaultValue={arrow?.label} placeholder="Enter a label" maxLength={14} onChange={e => updateArrow({ ...arrow, label: e.target.value })} />
          </InputGroup>
          <FormControl type="color" defaultValue={arrow?.color} size="sm" style={{ width: '40px', borderLeft: 0 }} onChange={e => updateArrow({ ...arrow, color: e.target.value })} />
          <button onClick={saveArrow} type="button" className="btn btn-sm btn-success px-2 ml-3">
            <i className="fa fa-check fa-fw"></i>
            <span className="sr-only">Save Arrow</span>
          </button>
          <button onClick={removeArrow} type="button" className="btn btn-sm btn-danger px-2">
            <i className="fa fa-times fa-fw"></i>
            <span className="sr-only">Remove Arrow</span>
          </button>
        </div> }
        <div className="ml-auto">
          <button onClick={clearArrows} type="button" className="text-nowrap btn btn-sm px-3 btn-light">&times; Clear</button>
        </div>
    </Navbar>
  )
}