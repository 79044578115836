import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, uploadComplete, updateFile, clearUploads } from '../data/store';
import uploader from '../utils/uploader';

export default function useUploads(){
  const { files } = useSelector(state => state.uploads);
  const dispatch = useDispatch();

  const upload = useCallback((props) => {
    dispatch(uploadFile(props));
  }, [dispatch]);

  const update = useCallback((props) => {
    dispatch(updateFile(props));
  }, [dispatch]);

  const complete = useCallback((props) => {
    uploader.remove(props.uid);
    dispatch(uploadComplete(props));
  }, [dispatch]);

  const cancel = useCallback((props) => {
    uploader.remove(props.uid);
    dispatch(uploadComplete(props));
  }, [dispatch]);

  const clear = useCallback((props) => {
    dispatch(clearUploads(props));
  }, [dispatch]);

  return { upload, complete, update, cancel, clear, files };
};
