import { useState, useRef, useEffect } from 'react';
import { assetService } from "@turnaroundfactor/common";
import { useSession, useSettings, useRoom } from "@turnaroundfactor/react";
import { Popover, Overlay } from "react-bootstrap";
import axios from 'axios';

const icons = {
  cad: 'fa-cubes',
  document: 'fa-file-pdf',
  media: 'fa-image'
};


export default function Asset({ asset, refetch }){
  const { filename, uuid, userId, type, status } = asset;
  
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteBtnRef = useRef(null);
  
  const btnRef = useRef(null);
  const [showCADConfirm, setShowCADConfirm] = useState(false);
  
  const [loading, setShowLoading] = useState(false);
  const { update: updateSettings, settings } = useSettings();
  const { activeMedia, activeDocument, activeSidebar, sidebar } = settings;

  const { users } = useRoom();
  const onsite = users.find(m => m.state === 'InRoom' && m.type === 'OnSiteUser');

  const { user } = useSession();
  const { uuid: uid } = user;

  const processing = (loading || status !== 'complete')

  function open(event) {
    event?.preventDefault();
    if ( processing ) return;
    if (type === 'media') {
      updateSettings({ activeMedia: asset });
      return;
    } else if (type === 'document') {
      updateSettings({ 
        activeTool: "pdf",
        activeDocument: asset
      });
    } else if (type === 'cad') {
      setShowCADConfirm(true);
      return;
    }
  }

  async function sendToOnSite() {
    setShowCADConfirm(false);
    setShowLoading(true);
    try{
      const { data } = await axios.get(assetService('fetch'), { params: { uuid }});
      onsite && onsite?.send('HL2_CAD_processing', {
        version: 1,
        filename,
        url: data.url,
        user: { name: user.name, uuid: user.uuid }
      });
    } catch (e) {
      // TODO: Do what?
    }

    setShowLoading(false);
  }

  async function deleteAsset(event) {
    event?.preventDefault();
    setShowConfirm(false);
    try{
      await axios.delete(assetService('destroy'), { data: { uuid } });
    } catch(e) {}
    try{ 
      refetch();
    }catch(e){}
  }

  useEffect(() => {
    if ( activeSidebar !== 'assets' || !sidebar ) {
      setShowCADConfirm(false);
      setShowConfirm(false);
    }
  }, [activeSidebar, sidebar]);


  const isActive = (activeDocument === uuid || activeMedia?.uuid === uuid );

  return (
    <li className="d-flex asset align-items-center">
        <button type="button" className="btn btn-sm px-0 d-flex mw-100 align-items-center flex-grow-1 flex-shrink-1" onClick={open} data-testid="button" ref={btnRef}>
          <i className={`fa fa-fw ${!processing ? icons[type] : 'fa-sync fa-spin'} icon`}></i>
          <span className="asset-filename text-truncate text-nowrap">{filename}</span>
        </button>
        {type === 'cad' && 
        <Overlay show={showCADConfirm} target={btnRef} transition={false}>
        {(props) => (
          <Popover {...props}>
            <Popover.Content>
              <strong className="d-block text-nowrap">Ask On-site user to view this model?</strong>
              <div className="d-flex py-2">
                <button type="button" className="btn btn-sm btn-primary flex-grow-1" onClick={sendToOnSite}>Request</button>
                <button type="button" className="btn btn-sm btn-light flex-grow-1" onClick={e => setShowCADConfirm(false)}>Cancel</button>
              </div>
            </Popover.Content>
          </Popover>
        )}
        </Overlay>
        }
        {userId === uid && 
        <>
          {!isActive && userId === uid &&
          <button type="button" className="btn btn-sm text-danger py-0" onClick={e => setShowConfirm(true)} ref={deleteBtnRef}>
            <i className="fa fa-fw fa-times-circle"></i>
            <span className="sr-only">Delete</span>
          </button>}
          <Overlay show={ !isActive && showConfirm} target={deleteBtnRef} transition={false}>
            {(props) => (
              <Popover {...props}>
                <Popover.Content>
                  <strong className="d-block text-nowrap">Permanently delete?</strong>
                  <div className="d-flex py-2">
                    <button type="button" className="btn btn-sm btn-light mr-2" onClick={e => setShowConfirm(false)}>Cancel</button>
                    <button type="button" className="btn btn-sm btn-danger" onClick={deleteAsset}>Confirm</button>
                  </div>
                </Popover.Content>
              </Popover>
            )}
          </Overlay>
        </>}
    </li>
  )
}