function get(){
  return JSON.parse(window.localStorage.getItem('config') || "{}");
}

function set(config) {
  const base = get();
  window.localStorage.setItem('config', JSON.stringify({
    ...base,
    ...config
  }));
}

const store = {
  get: get,
  set: set
}

export default store;