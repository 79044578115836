import adapt from '@turnaroundfactor/common';
import React, { useRef, useState } from 'react';

function CreateRoom({ onSubmit, onCancel }){

  const [error, setError] = useState(null);
  const inpt = useRef(null);

  async function roomCreate(){
    const name = inpt.current.value;
    const valid = inpt.current.checkValidity();
    
    if( name === '' ){
      setError('please provide a room name');
      return false;
    } else if ( valid === false ){
      setError('invald room name');
      return false;
    }

    let room;

    try{
      room = await adapt.createRoom(name);
    } catch (e) {
      const msg = e.responseBody ? e.responseBody : e.message;
      setError(msg);
      return false;
    }

    if( room === false ){
      setError('room already exists');
      return false;
    } else {
      setError(null);
    }

    onSubmit(room);
  };


  return (
    <div className="row">
      <h2 className="h5 mb-0 col pt-2 flex-grow-0 text-nowrap">Create Session</h2>
      <div className="col col-md-6 flex-grow-1 flex-shrink-0 d-flex">
        <div className='flex-grow-1'>
          <input type="text" className={`form-control ${error === null ? '' : 'is-invalid'}`} ref={inpt} placeholder="Enter your room name" required size="25" />
          { error !== null ? (<small className='d-block text-danger'>{error}</small>) : (
            <small className="d-block text-muted">may not contain special characters</small>
          )}
        </div>
        <div>
          <button type="button" className="btn btn-dark mr-1" onClick={roomCreate}>Start Session</button>
        </div>
      </div>
      <div className="col col-md-1 ml-auto">
        <button type="button" className="btn btn-light fa-inline fa-arrow-left" onClick={_ => onCancel()}>Back</button>
      </div>
    </div>
  )
}


export default CreateRoom;