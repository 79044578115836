import React from 'react';
import { useSettings } from '@turnaroundfactor/react';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';

function DocumentPrompt({ user, document }){

  const { uuid, filename } = document;
  const { update: updateSettings } = useSettings();

  function approve(){    
    toast.dismiss(uuid);
    updateSettings({ 
      activeTool: "pdf",
      activeDocument: document
    });
  }

  const action = (
    <button type="button" className="btn btn-dark btn-sm" onClick={approve}>
      <i className="fa fa-check-circle fa-fw"></i> <small>Open Document</small>
    </button>);

  return <Toast 
            title="Document request" 
            message={`${user?.name} would like you to view ${filename}`}
            action={action}
            icon="fa-info-circle" 
          /> 
}

export default DocumentPrompt; 