import { useRoom, useSettings, useSession } from '@turnaroundfactor/react'
import React from 'react'
import LocalVideo from './LocalVideo'
import PeerUser from './PeerUser'
// import TempUser from './__tests__/TempUser'

const baseCols = 5;
// const testProps = { name: 'Test Item', type: 'RemoteExpert', on: ()=> {}, off: ()=> {}, one: ()=> {}, getTracks: ()=> ([]), connected: true };
// const xtra  = [
//   { uuid: '12345', ...testProps },
//   { uuid: '67890', ...testProps },
//   { uuid: '38291', ...testProps },
//   { uuid: '35532', ...testProps },
//   { uuid: '21093', ...testProps },
//   { uuid: '19494', ...testProps },
//   { uuid: '10212', ...testProps },
//   { uuid: '21093', ...testProps },
//   { uuid: '19494', ...testProps },
//   { uuid: '10212', ...testProps }, 
//   { uuid: '10212', ...testProps } 
// ]

function getColumnCount(count, activeTool, pinned = null){
  if ( count === 1 ) return 1;
  if ( activeTool === 'pdf' ) return 1;
  
  if ( pinned ) {
    const ncount = count - 1;
    return ( ncount <= baseCols) ? baseCols : Math.ceil(Math.sqrt(ncount));
  }
  
  //if ( count < baseCols ) return count; 
  return Math.ceil(Math.sqrt(count));
}

function getRowCount(count, activeTool, cols, pinned){
  if ( count === 1 ) return 1;
  if ( activeTool === 'pdf' ) return count;
  if ( pinned ) {
    return Math.round(Math.sqrt(cols));
  }

  return Math.ceil(Math.sqrt(cols));
}

export default function VideoMain(){
  const { user } = useSession();
  const { users } = useRoom();
  const { settings: { activeTool, AREnabled, pinned } } = useSettings();

  const mlist = users.filter(m => m.state === 'InRoom') || [];
  const count = mlist.length + 1;
  //const count = xtra.length + 1;
  
  
  const cols    = getColumnCount(count, activeTool, pinned);
  const rows    = getRowCount(count, activeTool, cols, pinned);
  const pin     = mlist.find(u => u.uuid === pinned)?.uuid || null;
  const hasPin  = pin == null ? (pinned === user.uuid || (pinned === null && count <= 2)) : true;
  const compact = (['screenshot', 'draw'].includes(activeTool) && AREnabled) || activeTool === 'pdf';

  return (
    <div id="video_main">
      <div id="video_grid" style={{ '--video-count': count, '--video-cols': cols, '--video-rows': rows }} data-count={count} data-pinned={hasPin} data-compact={compact}>
        <div className={`item local ${(pinned === user.uuid || (pinned === null && count <= 2)) ? 'pinned' : ''}`}>
          <LocalVideo />
        </div>
        { mlist.map((m, i) => 
          <div className={`item ${pin === m.uuid ? 'pinned' : ''}`} key={m.uuid}>
            {/* <TempUser {...m} /> */}
            <PeerUser name={m.name || 'User'} uuid={m.uuid} type={m.type} />
          </div>
        )}
      </div>
    </div>
  );
}
