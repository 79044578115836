
export default function Toast(props){
  const { 
    title = null, 
    message,
    icon = null,
    action = null
  } = props;

  return (
    <div className="d-flex flex-row">
      { icon && 
      <div className="toast-icon pr-2 text-center">
        <i className={`fa fa-md text-dark ${icon}`} />
      </div>}
      <div className="small d-flex flex-column">
        { title && <strong className="d-block text-dark">{title}</strong> }
        {message}
        { action && 
        <div className="d-block pt-2">
          {action}
        </div>}
      </div>
    </div>
  );
}