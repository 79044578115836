import adapt from '@turnaroundfactor/common';
import { useRoom, useSettings, useSession } from '@turnaroundfactor/react';
import React, { useEffect, useRef } from 'react';
import getLocalStream from '../../utils/getLocalStream';
import Avatar from '../../components/Avatar';
import { Dropdown } from 'react-bootstrap';

function LocalVideo(){
  const { settings, update: updateSettings } = useSettings();

  const vidRef = useRef(null);
  const wrap   = useRef(null);
  const audio  = useRef(null);
  
  const { user } = useSession();
  const { broadcast } = useRoom();
  
  const { audioEnabled, videoEnabled, pinned } = settings;

  useEffect(()=>{
    const player = vidRef.current;

    async function setupLocal(){
      const stream = await getLocalStream();
      player.srcObject = stream;
      
      if( stream && stream.getTracks ){
        stream.getVideoTracks().forEach(t => t.enabled = videoEnabled);
        stream.getAudioTracks().forEach(t => t.enabled = audioEnabled);
      }
      
      try{
        await player.play();
      }catch(e){}

      setupContext(stream);
    }

    async function setupContext(stream){
      if (stream === null || stream === undefined ) return;

      const audioContext = new AudioContext();
      await audioContext.audioWorklet.addModule(`${process.env.PUBLIC_URL}/assets/vumeter-processor.js`);
      
      const mic  = audioContext.createMediaStreamSource(stream);
      const node = new AudioWorkletNode(audioContext, 'vumeter');

      mic.connect(node);
      //analyser.connect(audioContext.destination);
      audio.current = audioContext;

      let msgSent = false;

      node.port.onmessageerror = (event) => {
        console.log("ERR: ", event);
      }
      node.port.onmessage = (event) => {
        const volume = event.data.volume * 100;

        if( volume > 5 ){
          msgSent = true;
          broadcast && broadcast('SPEAKING', { speaking: true });

          if ( wrap.current ){
            wrap.current.classList.add('-speaking');
          }

        }else if( msgSent === true ){

          if ( wrap.current ){
            wrap.current.classList.remove('-speaking');
          }

          broadcast && broadcast('SPEAKING', { speaking: false });
        }
      }
    }

    setupLocal();

    return () =>{
      try{ 
        audio.current.close();
      }catch(e){}

      const stream = player.srcObject || adapt.mediaStream;
      
      if( stream && stream.getTracks ){
        stream.getTracks().forEach(t => {
          t.stop()
        });
        
        player.pause();
        player.srcObject = null;
        adapt.mediaStream = null;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    const player = vidRef.current;
    if( player ){
      player.muted  = true;
      player.volume = 0;
    }

  }, [vidRef]);

  const isPinned = pinned === user.uuid;

  return (
    <div className={`peer-user local-user ${videoEnabled === false ? '-muted' : ''}`} ref={wrap}>
      <figure className="video" id="localVideoWrap">
        <video id="localVideo" autoPlay playsInline muted ref={vidRef}></video>
        { videoEnabled === false && 
          <div data-testid="avatar" className="peer-avatar d-flex flex-column align-items-center">
            <Avatar user={user} size={75} />
          </div> 
        }
        <div className="local-controls d-flex pr-2">
          <button data-testid="muteAudio" className="btn btn-xs btn-link p-0 text-white" onClick={_ => updateSettings({ audioEnabled: !audioEnabled })}>
            <i className={`fa ${audioEnabled === false ? 'fa-microphone-slash text-danger' : 'fa-microphone'}`}></i>
          </button>
          <button data-testid="muteVideo" className="btn btn-xs btn-link p-0 text-white" onClick={_ => updateSettings({ videoEnabled: !videoEnabled })}>
            <i className={`fa ${videoEnabled === false ? 'fa-video-slash text-danger' : 'fa-video'}`}></i>
          </button>
        </div>
      </figure>
      <Dropdown className="peer-options">
        <Dropdown.Toggle variant="link text-white">
          <i className="fas fa-fw fa-ellipsis-v"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="rounded">
          <Dropdown.Header className="pb-0">{user.name}</Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item as="button" onClick={e => updateSettings({ pinned: (isPinned ? null : user.uuid) })}>{ isPinned ? 'Un-Pin' : 'Pin User' }</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default LocalVideo;