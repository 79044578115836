import { useRoom } from '@turnaroundfactor/react'
import React, { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import Message from './Message'
import Toast from '../../components/Toast';


function Chat({ open }){
  const { messages, broadcast } = useRoom();
  const textArea    = useRef(null);
  const loglist     = useRef(null);
  const btnStyles   = { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
  const list        = useRef(messages.map(m => m.message_uuid));

  function sendMessage(event){
    event.preventDefault();
    const { value } = textArea.current;
    
    if( value !== '' ){
      broadcast('MESSAGE', value);
      textArea.current.value = "";
    }
  }

  function submitChat(event){
    const code = event.which || event.keyCode;
    
    if( code === 13 && !event.shiftKey ){
      event.preventDefault();
      sendMessage(event);
      return false;
    }
  }

  useEffect(()=>{
    if( loglist.current ){
      loglist.current.scrollTop = loglist.current.scrollHeight;
    }

    const notify = messages.filter(m => 
      !list.current.includes(m.message_uuid)
    );
    
    const mids = notify.map(m => m.message_uuid);
    list.current = [...list.current, ...mids];

    if ( notify.length > 0 && open === false ){
      
      const msg = notify[notify.length - 1];
      const { user, body } = msg;

      toast(<Toast title={user?.name} message={body?.toString().replaceAll("\n", "<br />")} icon="fa-comments" />, {
        toastId: msg.message_uuid,
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        draggable: false
      });

    }

  }, [messages, open]);


  return (
    <section id='chat' className="d-flex flex-column p-4">
      <h3 className="h5 mb-2 text-dark font-weight-bold">Chat</h3>
      <ul id="chat_log" ref={loglist} className="list-unstyled flex-grow-1">
        { messages.map((m, i)=> <Message key={i} {...m} />) }
      </ul>
      <form id="chat_form" className="form mt-auto" onSubmit={sendMessage}>
        <textarea className="form-control" data-testid="textarea" ref={textArea} onKeyDown={submitChat}></textarea>
        <button type="submit" data-testid="submit" className="btn btn-dark d-block w-100 fa-inline fa-comment" style={btnStyles}>Send Message</button>
      </form>
    </section>
  )
}

export default Chat;