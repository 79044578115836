/* eslint-disable react-hooks/exhaustive-deps */
import adapt from '@turnaroundfactor/common';
import { useSettings, useSession } from '@turnaroundfactor/react';
import React, { useEffect, useState, useRef } from 'react';
import getLocalStream from '../../utils/getLocalStream';
import localStore from '../../utils/localStore';
import getMediaDevices from '../..//utils/getMediaDevices';
import Avatar from '../../components/Avatar'

function AVTest({ active, close }){
  const { settings, update: updateSettings } = useSettings();
  const { user } = useSession();
  
  const { audioEnabled, videoEnabled, audioDevice, videoDevice } = settings;
  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);

  const node = useRef(null);

  function toggleAudio(val){
    const player = node.current;
    const stream = player.srcObject;
    if( stream && stream.getAudioTracks ){
      stream.getAudioTracks().forEach(t => t.enabled = val );
    }
    updateSettings({ audioEnabled: val });
    localStore.set({ audioEnabled: val });
  }

  function toggleVideo(val){
    const player = node.current;
    const stream = player.srcObject;
    if( stream && stream.getVideoTracks ){
      stream.getVideoTracks().forEach(t => t.enabled = val );
    }
    updateSettings({ videoEnabled: val });
    localStore.set({ videoEnabled: val });
  }

  // function closeVideo(player){
  //   if( player && player.srcObject ){
  //     const stream = player.srcObject;
  //     if( stream && stream.getTracks ){
  //       stream.getTracks().forEach(t => t.enabled = false);
  //       player.srcObject = null;
  //     }
  //   }
  // }

  async function setupVideo(player){
    const stream = await getLocalStream();
    
    if ( stream ) updateSettings({ mediaAllowed: true });
    if( player ){
      
      if ( player.srcObject ) {
        player.srcObject.getTracks().forEach(t => {
          t.enabled = false;
          t.stop()
        });
        player.srcObject = null;
      }

      player.srcObject = stream;

      if( stream && stream.getTracks ){
        stream.getVideoTracks().forEach(t => t.enabled = videoEnabled );
        stream.getAudioTracks().forEach(t => t.enabled = audioEnabled );
      }
    }      
  }

  useEffect(() => {

    async function getDevices() {
      const devices = await getMediaDevices();
      setCameras(devices.video);
      setMics(devices.audio);
    }

    adapt.on('STREAM_CAPTURE', getDevices);
    getDevices();

    return _ => adapt.off('STREAM_CAPTURE', getDevices);
  }, []);

  useEffect(() => {
    setupVideo(node.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDevice, audioDevice]);


  return (
    <div id="avtest">
      <div className="d-flex flex-column">
        <figure id="av_test_video" className="embed-responsive embed-responsive-16by9 video mb-2 position-relative">
          <video className="bg-black" autoPlay muted playsInline ref={node}></video>
          { videoEnabled === false && 
          <div data-testid="avatar" className="peer-avatar d-flex flex-column align-items-center">
            <Avatar user={user} size={75} />
          </div> 
          }
          <div className="controls d-flex justify-content-end pb-2 pr-2">
            <button type="button" className="btn btn-light d-inline-flex justify-content-center align-items-center btn-48 h2 mb-0 rounded-circle me-2 mr-2" onClick={e => toggleVideo(!videoEnabled)}>
              <i className={`fa fa-fw ${videoEnabled ? 'fa-video' : 'fa-video-slash text-dark'}`}/>
            </button>
            <button type="button" className="btn btn-light d-inline-flex justify-content-center align-items-center btn-48 h2 mb-0 rounded-circle" onClick={e => toggleAudio(!audioEnabled)}>
              <i className={`fa fa-fw ${audioEnabled ? 'fa-microphone' : 'fa-microphone-slash text-dark' }`}/>
            </button>
          </div>
        </figure>
        <div>
          <div className="d-flex align-items-center mb-2 py-2" id="av_test_devices">
            <div className="input-group mr-1">
              <div className="input-group-prepend" aria-hidden="true">
                <span className="input-group-text rounded-left"><i className="fa fa-video fa-fw"></i></span>
              </div>
              <select value={videoDevice} onChange={e => updateSettings({ videoDevice: e.target.value })} className="form-control form-control-sm rounded-right">
                {cameras.map((c, i) => 
                  <option key={i} value={c.id}>{c.label}</option>
                )}
              </select>
            </div>
            <div className="input-group ml-1">
              <div className="input-group-prepend" aria-hidden="true">
                <span className="input-group-text rounded-left"><i className="fa fa-microphone fa-fw"></i></span>
              </div>
              <select value={audioDevice} onChange={e => updateSettings({ audioDevice: e.target.value })} className="form-control form-control-sm rounded-right">
                {mics.map((c, i) => 
                  <option key={i} value={c.id}>{c.label}</option>
                )}
              </select>
            </div>            
          </div>
        </div>
      </div>
    </div>
  )
}

export default AVTest;