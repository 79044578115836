import React from 'react'
import AVTest from '../dashboard/AVTest'
import adapt from '@turnaroundfactor/common'
import { useSettings } from '@turnaroundfactor/react'
import { useNavigate } from 'react-router-dom'

import logo from '../../assets/logo.svg'

export default function Lobby({ joinRoom, room }) {

  const navigate = useNavigate();  
  const { update: updateSettings } = useSettings();
  
  function cancelJoin(){
    adapt.disconnect();
    updateSettings({ room: null, prevRoom: null, pinned: null });
    navigate('/');
  }

  return (
    <div id="connection_main">
      <div id="lobby" className="p-4 bg-white rounded shadow-sm">
        <img src={logo} height="32" className="d-md-none mb-3" alt="SME Connect" />
        <div className="row align-items-center">
          <div className="col-md-5">
            <AVTest active={true} />
          </div>
          <div className="col-md-6 pl-md-5 mb-3">
            <img src={logo} height="32" className="d-none d-md-block mb-3" alt="SME Connect" />
            <h2 className="pt-2 mb-1 text-primary">{room.name}</h2>
            <small className="text-muted d-block mb-4">{room.shortname}</small>
            <p>Please configure your audio and video, and proceed below when ready.</p>
            <hr />
            <div className="d-md-flex flex-md-row justify-content-md-between">
              <button type="button" className="btn btn-dark mr-2" onClick={joinRoom}>
                Join Session <i className="fa fa-fw ml-2 fa-arrow-right"></i>
              </button>
              <button type="button" className="btn btn-link" onClick={cancelJoin}>
                <i className="fa fa-fw fa-times mr-1"></i> Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}