import adapt from '@turnaroundfactor/common';
import React from 'react';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';

function ApproveMember({ peer }){

  function approve(){
    try{ 
      adapt.room.approve(peer);
    }catch(e){
      console.error('Error during approve member.', e);
    }
    
    toast.dismiss(peer.uuid);
  }

  const action = (
    <button type="button" className="btn btn-dark btn-sm" onClick={approve}>
      <i className="fa fa-check-circle fa-fw"></i> <small>Approve</small>
    </button>);

  return <Toast 
            title="New Member" 
            message={`${peer.name} has requested permission to join the room.`} 
            action={action}
            icon="fa-info-circle" 
          /> 
}

export default ApproveMember; 