import adapt, { emailService } from '@turnaroundfactor/common';
import axios from 'axios';
import { useState } from 'react';

const userBase = {
  type: "RemoteExpert",
  user_class: "WebUI"
}

const defaultError = 'Error signing in, please try again later';

export function useGoogleAuth(onSuccess) {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);

  const handler = async (response) => {
    if( response && response.error ){
      console.log(`Google auth error [ ${response.error} ] - ${response.details}`);
      setError('Google login failed, please try again.');
      setActive(false);
      return false;
    }

    const { credential } = response;
    const [,token] = credential.split('.');
    const payload = JSON.parse(atob(token));
    const { email, name } = payload;

    setActive(true);
    setError(null);

    try{
      const user = await adapt.login({ 
        method: 'google',
        email,
        name,
        token: credential,
        ...userBase
      });

      if( user === false || user === null ) { 
        setError('Google auth failed. Please try again');
        setActive(false);
        return false;
      }

      await user.refresh();
      onSuccess && onSuccess(user);

    }catch(e){
      setError(e?.responseBody || e?.message || defaultError);
      setActive(false);
      return;
    }

    setActive(false);
  }

  return {
    active,
    error,
    handler
  }
}

let cacAttempts = 0;
const cacDelay = [
  0,
  5000,
  15000,
  30000,
  60000
];

const axiosTimeout = axios.defaults.timeout;
const cacTries = 4;
const wait = ms => new Promise(r => setTimeout(r, ms));
const messages = [
  'Loading certificate',
  'Checking certificate',
  'Validating credentials',
  'Authenticating'
]


export function useCACAuth(onSuccess, onError = () => {}) {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);

  function shouldRetry(e) {
    const code = e.code || e.cause?.code;
    const scenarios = [
      'ERR_NETWORK', 
      '502',
      '403'
    ];

    return (code && scenarios.includes(code.toString()));
  }

  const handler = async ()=>{
    setActive(true);
    setError(null);

    axios.defaults.timeout = 30000;

    try{
      const user = await adapt.login({
        method: 'cac',
        ...userBase
      });

      axios.defaults.timeout = axiosTimeout;

      if( user === false || user === null ) { 
        setError("CAC Auth failed, please try again.");
        setActive(false);
        return false;
      }

      setActive(false);
      onSuccess && onSuccess(user);
      
    }catch(e){
      if ( shouldRetry(e) && cacAttempts < cacTries ) {
        onError && onError(messages[cacAttempts]);
        cacAttempts += 1;
        // console.log(`Retry after: ${cacDelay[cacAttempts]}`);
        wait(cacDelay[cacAttempts]).then(handler);
      } else {
        cacAttempts = 0;
        axios.defaults.timeout = axiosTimeout;
        setError(e?.responseBody || e?.message || defaultError);
        setActive(false);
      }
    }
  }

  return {
    active,
    error,
    handler
  }
}

export function useOTPAuth(onSuccess) {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);

  const reset = () => {
    setError(null);
    setActive(false);
  }

  const handler = async ({ code, name, email })=>{
    setActive(true);
    setError(null);

    let token;

    try{
      const { data } = await axios.post(emailService('receive'), { 
        n: name,
        e: email,
        p: code
      });

      token = data.token;
      console.log(`Login with token`, token);

    } catch (e) {
      setError('Invalid code, please try again');
      setActive(false);
      return false;
    }

    try{
      const user = await adapt.login({
        email,
        name,
        ...userBase,
        method: 'email',
        token: JSON.stringify({ data: token })
      });

      if( user === false || user === null ) { 
        setError("Authentication failed, please try again.");
        setActive(false);
        return false;
      }

      onSuccess && onSuccess(user);
      
    }catch(e){
      console.log({e});
      setError(e?.responseBody || e?.message || defaultError);
      setActive(false);
      return;
    }

    setActive(false);
  }

  return {
    active,
    error,
    handler,
    reset
  }
}