import React, { useState, useRef } from 'react'
import IconText from './IconText'

function Recorder(){
  const [recording, setRecording] = useState(false);
  const recorder = useRef(null);

  async function toggleRecording(){
    if( recording === false ){
      let stream;

      try {
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
          preferCurrentTab: true
        });
      } catch (e) {
        return true;
      }

      const chunks = [];

      const video = stream.getVideoTracks();
      const vtrack = video[0];

      if (vtrack) {
        vtrack.onended = () => {
          if( recorder.current ){
            recorder.current.stop();
          }
          setRecording(false);
        };
      }

      chunks.current = [];
      recorder.current = new MediaRecorder(stream);
      recorder.current.ondataavailable = e => chunks.push(e.data);
      recorder.current.onstop = _ => {
        const blob = new Blob(chunks, { type: chunks[0].type });
        stream.getTracks().forEach(t => t.stop());
        const url  = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `SME_Connect_Screen_Record_${new Date().getTime()}.webm`;
        link.click();
        window.URL.revokeObjectURL(url);
      };
      
      setRecording(true);
      recorder.current.start();

    }else{
      if( recorder.current ){
        recorder.current.stop();
      }
      
      setRecording(false);
    }
  }

  return (
    <button className={`btn btn-dark py-3 px-4 toolbar-button`} data-toggle='tooltip' title="Record Screen" onClick={toggleRecording}>
      <IconText icon={`fa fa-circle ${recording === true ? 'text-danger' : ''}`} label={'Record'} />
    </button>
  )
}

export default Recorder;