import { useTool } from '@turnaroundfactor/react';
import React, { useEffect, useRef, useState } from 'react';
import Tool from '../Tool';

function GenericVideo({ peer }){
  const vidRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const { status } = useTool(peer, {
    name: peer.name,
    useVideo: true,
    usePolling: false
  });

  function teardownVideo(player){
    const stream = player.srcObject;
    if( stream && stream.getTracks ){
      stream.getTracks().forEach(t => {
        t.stop()
      });
      
      player.pause();
      player.srcObject = null;
    }
  }

  useEffect(()=>{
    const player = vidRef.current;

    function trackHandler(result){
      const { track } = result;

      if( track ){
        if( vidRef.current.srcObject ){
          vidRef.current.srcObject.getTracks().forEach(t => {
            if ( t.readyState === 'ended' ) player.srcObject.removeTrack(t);
          });

          vidRef.current.srcObject.addTrack(track);
        }else{
          vidRef.current.srcObject = new MediaStream([track]);
        }
      }
    }

    peer.on('DISCONNECT', _ => {
      if( vidRef.current && vidRef.current.srcObject ){
        console.log(`Disconnected from ${peer.name}. Tearing down video.`);
        teardownVideo(vidRef.current);
      }
    });

    peer.on('TRACK', trackHandler);
    return _ => {
      teardownVideo(player);
      peer.off();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = [
    { icon: "fa-expand-arrows-alt", action: e => setExpanded(!expanded) }
  ]

  
  return (
    <Tool title={peer.name} uuid={peer.uuid} settings={settings}>
      { status !== 'connected' && (
        <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100 position-absolute connect text-center" style={{ zIndex: 10 }}>
          <i className="fa fa-spinner fa-spin fa-2x text-white"></i>
          <span className="text-white font-italic d-block text-center small mt-1">Connecting...</span>
        </div>
      )}
      <div className={`tool-expand ${expanded === true ? '-expanded' : ''}`}>
        <div className="tool-expand-inner">
            <figure className="embed-responsive embed-responsive-16by9 video">
              <video ref={vidRef} autoPlay playsInline muted></video>
            </figure>
        </div>
        { expanded === true && 
        <button className="d-flex close bg-dark text-white" onClick={e => setExpanded(false)}>
          <i className="fa fa-times"></i>
        </button> }
      </div>
    </Tool>
  );
}

export default GenericVideo;