import React from 'react'
import Logo from './Logo';

// Displays a box used for login and other smaller screens.

export default function Panel({ children, className = '', logo = true, wide = false }){
  return (
    <section className={`panel p-4 bg-white rounded shadow-sm d-flex flex-column align-items-center justify-content-center ${className} ${ wide === true ? '-wide' : '' }`}>
      { logo === true && <Logo />}
      <div className="panel-inner flex-grow-1 w-100">
        {children}
      </div>
    </section>
  );
}