import adapt from '@turnaroundfactor/common';
import { toast } from 'react-toastify';

const TIMEOUT = 30000; // 30 seconds

let timer;

// Run when offline for more than a minute.
function handleOfflineState() {
  if ( timer ) clearTimeout(timer);
  adapt.trigger('OFFLINE');
  try{
    toast.dismiss('network_offline');
  }catch(e){}
}

export default function onlineState(event){
  // setup timer when disconnected from the internet.
  if ( event.type === 'offline' ){
    timer = setTimeout(handleOfflineState, TIMEOUT);
    adapt.trigger('SERVICE_DISRUPTED');
    toast.error((<small>Internet connection lost. Please check your network settings.</small>), {
      toastId: 'network_offline',
      position: "top-right",
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      theme: 'colored',
      closeButton: false
    });

    return;
  }

  adapt.trigger('ONLINE');
  
  try{
    toast.dismiss('network_offline');
  }catch(e){}

  if ( timer ) {
    adapt.trigger('SERVICE_RESTORED');
    clearTimeout(timer);
  }
}