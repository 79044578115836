import React, { useState, useRef } from 'react';
import { useOTPAuth } from './helpers';

export default function OTP({ back, name, email, onSuccess, resend }) {

  const [sending, setSending] = useState(false);
  const [resending, setResending] = useState(false);
  const codeInput = useRef(null);

  const { active, error, handler, reset } = useOTPAuth(onSuccess);

  function handleEnterKey(event) {
    if (event && event.keyCode === 13) sendCode();
  }

  async function resendCode(event){
    reset();
    event?.preventDefault();
    setResending(true);
    await resend({ name, email });
    setResending(false);
  }

  async function sendCode() {
    const code = codeInput.current.value;
    codeInput.current.classList.remove('is-invalid');

    if ( code === '' || code.length < 8 ) {
      codeInput.current.classList.add('is-invalid');
    };

    reset();
    setSending(true);
    await handler({ name, email, code });
    setSending(false);
  }

  return (
    <div className="d-flex flex-column justify-content-center mx-auto justify-content-center" style={{ maxWidth: '320px' }}>
      <p className="mb-3">Enter the code you received in your email.</p>
      <div className="form-group mb-2">
        <input type="text" className="form-control text-center form-control-lg" pattern="[0-9]*" maxLength={8} inputMode="numeric" ref={codeInput} onKeyUp={handleEnterKey} required />
      </div>
      { error && <span className="d-block pb-2 text-danger">{error}</span> }
      <button type="button" className="btn btn-primary mb-2" onClick={sendCode} disabled={sending || active}>
        { (sending || active) && <i className="fa fa-fw fa-spinner fa-spin" /> } Continue
      </button>
      <button type="button" className="btn btn-secondary" onClick={resendCode} disabled={resending || active}>
      { resending && <i className="fa fa-fw fa-spinner fa-spin" /> } Resend Code
      </button>
      <div className="my-2">
        <button type="button" className="btn btn-link text-muted" onClick={e => back()}>
          <i className="fa fa-fw fa-arrow-left"></i> back
        </button>
      </div>
    </div>
  )
}