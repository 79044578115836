import { useSettings } from '@turnaroundfactor/react';
import React, { useEffect, useRef } from 'react';
import UnityHelp from './UnityHelp';

function UnityPlayer({ peer }){
  const { settings } = useSettings();
  const unity = useRef(null);
  const gameInstance = useRef(null);  
  
  useEffect(()=>{

    function onfocus() {
      gameInstance.current.SendMessage('JavaScriptHook', 'Focus'); 
    }
  
    function onblur() {
      gameInstance.current.SendMessage('JavaScriptHook', 'Blur'); 
    }
    
    if( gameInstance.current === null && window.UnityLoader ){
      console.log("OnSiteUser connected, creating unity instance.");
      gameInstance.current = window.UnityLoader.instantiate("unity_wrap", `${process.env.PUBLIC_URL}/WebGL.json`, { onProgress: (instance, progress)=>{
        if( progress === 1 ){          
          const canvas = document.querySelector('#unity_wrap > canvas');
          canvas.setAttribute('tabindex', '1');
          canvas.removeEventListener('focus', onfocus);
          canvas.addEventListener('focus', onfocus);

          canvas.removeEventListener('blur', onblur);
          canvas.addEventListener('blur', onblur);
        }

      } });
    }

    if( settings.connected === false ) return;

    function updateUnitySize(event){
      const main = document.getElementById('unity_main');
      const wrap = document.getElementById('unity_wrap');
      wrap.style.width = main.offsetWidth;
      wrap.style.height = main.offsetHeight;

    }
    
    window.addEventListener('resize', updateUnitySize);
    
    unity.current.addEventListener("focus", (event)=>{ 
      //console.log('Unity Focus');
      gameInstance.current.SendMessage('JavaScriptHook', 'Focus'); 
    });

    unity.current.addEventListener("blur", (event) =>{ 
      //console.log('Unity Blur');
      gameInstance.current.SendMessage('JavaScriptHook', 'Blur'); 
    });
    

    return _ => window.removeEventListener('resize', updateUnitySize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.connected]);

  useEffect(() => {
    function triggerUnityCommand(data){
      try{
        const { method, message } = data;
        //console.log(`SEND MESSAGE TO UNITY: ${method}`);
        gameInstance.current.SendMessage('JavaScriptHook', method, message);
      }catch(e){
        //console.log("Unity message failed", e.message);
      }
    }
    
    peer.on('UNITY_COMMAND', triggerUnityCommand);
    return  _ => peer.off('UNITY_COMMAND');
  }, [peer]);


  return (
    <div id='unity_main' ref={unity} className="d-flex mh-100 flex-column align-items-center justify-content-center" tabIndex="1">
      <div id="unity_wrap" className="w-100 h-100">
      </div>
      <UnityHelp />
    </div>
  )
}

export default UnityPlayer;