const getMessagesSinceTime = (last, messages) => {
  const lastCheck = typeof last === 'string' ? new Date(last) : (last || new Date());
  const lastMessage = messages[messages.length - 1];
  if( lastMessage === null || lastMessage === undefined ) return null;
  if( lastCheck === null || lastCheck === undefined ) return null;

  const newItems = messages.filter(m => {
    const date = typeof m.datetime === 'string' ? new Date(m.datetime) : m.datetime;
    return ( date > lastCheck ) ? m : null;
  });

  return newItems.length;
}

export default getMessagesSinceTime;