import React from 'react'

export default function Message(props){
  const { body, user, url, datetime } = props;

  const date = typeof datetime === 'string' ? new Date(datetime) : datetime;
  let datestr = "";
  try{
    datestr = date.toLocaleString([], {
      hour: '2-digit', 
      minute:'2-digit'
    });
  }catch{}

  const bodystr = url ? `${url}`.toString().split(' ').map(u => `<a href='${u}' title='${u}' target='_blank'>${u}</a>`).join("<br />") : `${body}`.toString().replaceAll("\n", "<br />");

  return (
    <li className="mb-2 chat-message">
      <div className="d-flex justify-content-between">
        <h6 className="font-weight-bold m-0 text-truncate">{user?.name}</h6>
        <span className="text-muted small d-block pl-2 text-right">{datestr}</span>
      </div>
      <span dangerouslySetInnerHTML={{__html: bodystr }} />
    </li>
  );
}
