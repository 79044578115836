import adapt from '@turnaroundfactor/common'
import { useSession, useSettings } from '@turnaroundfactor/react'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import getAppURL from '../utils/getAppURL'
import Avatar from './Avatar'

function UserMenu({ user, room }){

  const { update: updateSettings } = useSettings();
  const { logout } = useSession();
  const [loading, setLoading] = useState(false);

  function showTour(event){
    updateSettings({ runTour: true });
  }


  async function performLogout(){
    if( window.confirm('Are you sure you want to sign out?') === false ){
      return false;
    }

    setLoading(true);

    await adapt.disconnect();
    await adapt.logout()
    logout();

    window.location.href = getAppURL();
  }

  function resetPage(){
    if( window.confirm('Refresh the page?') === false ){
      return true;
    }

    setLoading(true);

    adapt.disconnect().then(_ =>{
      window.location.href = getAppURL();
    }).catch(e => {
      window.location.href = getAppURL();
    });
    

  }

  if( user === null || user === undefined ){
    return <></>
  }

  const uname = user.name?.toString().split(":")[0];

  return (
    <Dropdown>
      <Dropdown.Toggle variant="dark" className="toolbar-button">
          { loading === true ? 
          <i className="fa fa-spinner fa-spin fa-fw ml-2"></i>:
          <Avatar user={user} size={24} color="#ddd" textColor="#333" />
          }
          <span className="small d-none d-md-inline-block px-2">
            {uname}
          </span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        { room && <>
          <Dropdown.Item onClick={showTour}>View Help</Dropdown.Item> 
          <Dropdown.Divider />
        </>}
        <Dropdown.Item onClick={performLogout}>
          Sign Out 
        </Dropdown.Item>
        <Dropdown.Item onClick={resetPage}>
          Reset Page
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserMenu;