import { Room } from '@turnaroundfactor/common';
import React from 'react';
import UserImage from './UserImage';

function displayedUsers(m) {
  return ['RemoteExpert', 'OnSiteUser'].includes(m.type) && m.state === 'InRoom';
}

export default function RoomInfo({room, joinRoom}){
  const members = room.members?.filter(displayedUsers);

  function pcount(){
    if( members.length === 1 ) return 'participant';
    return 'participants';
  }

  return (
    <li className="col-12 col-md-4 px-md-2 mb-3 room-info">
      <div className="p-3 border room-info-inner d-flex flex-column shadow-sm">
        <h5 className="btn btn-link text-left p-0" role="button" onClick={e => joinRoom(room)}>
          <span className="h6" data-testid="roomName">{room.name}</span>
          <small className="d-block text-muted" data-testid="roomShortname">{room.shortname}</small>
        </h5>
        { members && 
        <>
          <span className="d-block small mb-3">{members.length} {pcount()}</span>
          <div className="d-flex align-items-center flex-wrap mb-3">
            {members.map(m => <UserImage user={m} key={m.uuid} />)}
          </div> 
        </>}
        <div className="mt-auto">
          <button className="btn btn-primary btn-sm px-4 py-2 w-100" onClick={e => joinRoom(new Room(room))} disabled={!(room && room.shortname)}>
            {room?.shortname ? "Join" : <i className="fa fa-fw fa-spinner fa-spin mr-2"></i>}
          </button>
        </div>
      </div>
    </li>
  )
}