import React, { useState, useRef } from 'react';
import axios from 'axios';
import { emailService } from '@turnaroundfactor/common';
import OTP from './OTP';

export default function EmailLogin({ back, onSuccess }) {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  const nameInput = useRef(null);
  const emailInput = useRef(null);

  async function validateAndSend(event) {
    event?.preventDefault();
    const nameValid = nameInput.current.checkValidity();
    const emailValid = emailInput.current.checkValidity();

    const name = nameInput.current.value;

    if( nameValid === false ){
      nameInput.current.classList.add('is-invalid');
    }else{
      nameInput.current.classList.remove('is-invalid');
    }

    if( emailValid === false ){
      emailInput.current.classList.add('is-invalid');
    }else{
      emailInput.current.classList.remove('is-invalid');
    }
    
    if ( name === '' || email === '' ){      
      setError('please fill out all fields');
      return;
    }

    if ( nameValid && !emailValid ){
      setError('please enter a valid email');
      return;
    }

    setError(null);
    setSending(true);
    const { result, message } = await sendMail({ name, email });
    setSending(false);
    if ( result ) {
      setSent(true);
    } else {
      setError(message)
    }
  }

  async function sendMail({ name, email }) {   
    
    try {
      await axios.post(emailService('send'), {
        displayName: name,
        emailAddress: email
      });
      
      return { result: true };

    } catch (e) {
      console.error(e);
      return {
        result: false,
        message: 'could not send email link, please try again.'
      };
    }
  }

  if ( sent ) return <OTP name={nameInput.current.value} email={email} back={e => setSent(false) } onSuccess={onSuccess} resend={sendMail} />

  return (
    <div className="d-flex flex-column justify-content-center mx-auto justify-content-center" style={{ maxWidth: '300px' }}>
      <p className="mb-3">Enter your information below to continue</p>
      <div className="form-group mb-2">
        <input type="text" placeholder="Display Name" className="form-control" ref={nameInput} required />
      </div>
      <div className="form-group mb-2">
        <input type="email" placeholder="Email" className="form-control" onChange={e => setEmail(e.target.value)} ref={emailInput} required />
      </div>
      { error && <span className="d-block pb-2 text-danger">{error}</span> }
      <button type="button" className="btn btn-primary" onClick={validateAndSend} disabled={sending}>
        { sending && <i className="fa fa-fw fa-spinner fa-spin" /> } Continue
      </button>
      <div className="my-2">
        <button type="button" className="btn btn-link text-muted" onClick={e => back()}>
          <i className="fa fa-fw fa-arrow-left"></i> back
        </button>
      </div>
    </div>
  )
}