import React from 'react'
import domtoimage from '../utils/domToImage';

function ToolMain({ title, uuid, children, className="", settings = [] }){
  const domid = `tool_${uuid}`;

  const shotSetting = {
    icon: 'fa-images',
    action: async e => {
      const scale   = 2;
      const node    = document.getElementById(domid);
      const dataURL = await domtoimage.toPng(node, {
        height: node.offsetHeight * scale,
        width: node.offsetWidth * scale,
        style: {
          transform: `scale(${scale}) translate(${node.offsetWidth / 2 / scale}px, ${node.offsetHeight / 2 / scale}px)`,
          transformOrigin: "center",
          width: node.offsetWidth + "px",
          height: node.offsetHeight + "px"
        }
      });
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `${title}.png`;
      link.click();
      window.URL.revokeObjectURL(dataURL);
    }
  };

  return (
    <aside className={`tool border-bottom ${className}`}>
      <header className="tool-title d-flex flex-row align-items-center justify-content-between border-bottom pl-3 pr-1">
        <h6 className="my-0 ml-0 mr-2 px-0 py-3 small text-nowrap text-truncate block flex-grow-1" data-toggle="collapse" data-target={`#${domid}`} role="button">
          {title}
        </h6>
        <div className="tool-settings-options d-flex">
          { [shotSetting, ...settings].map((s, i)=> 
          <button key={i} type="button" className="btn btn-link btn-sm text-dark ml-auto" onClick={s.action}>
            <i className={`fa fa-fw ${s.icon}`}></i>
            <span className="sr-only">Options/Settings</span>
          </button>
          )}
        </div>
        <i className="fa fa-fw fa-caret-down caret"></i>
      </header>
      <div className="tool-display position-relative collapse show px-3" id={domid}>
        { children }
      </div>
    </aside>
  )
}

export default ToolMain;