import { useRoom, useSettings } from '@turnaroundfactor/react'
import React, { useEffect, useState, useRef } from 'react'
import { Popover, Overlay } from "react-bootstrap";
import getMessagesSinceTime from '../utils/getMessagesSinceTime'
import Recorder from './Recorder'
import Invite from './EmailInvite'
import NavAction from './NavAction'


function BottomBar(){
  const { settings, update: updateSettings } = useSettings();
  const { 
    activeTool, 
    AREnabled, 
    activeDocument, 
    activeSidebar, 
    sidebar, 
    lastMessageCheck, 
  } = settings;

  const { members, tools, uuid: roomUUID, messages } = useRoom();
  const [newMessages, setNewMessages] = useState(getMessagesSinceTime(lastMessageCheck, messages));
  const onSite = members?.filter(m => m.state === 'InRoom' && m.type === 'OnSiteUser');

  const inviteBtn = useRef(null);

  const [showInvite, setShowInvite] = useState(false);

  useEffect(() => {
    setNewMessages(getMessagesSinceTime(lastMessageCheck, messages));
  }, [messages, lastMessageCheck, activeSidebar]);
   

  function toggleDraw(event){
    if( activeTool === 'draw' ){
      updateSettings({ activeTool: null });
    }else{
      updateSettings({ activeTool: "draw" });
    }
  }

  function toggleScreenshot(){
    if( activeTool === 'screenshot' ) {
      updateSettings({ activeTool: null });
    }else{
      updateSettings({ activeTool: "screenshot" });
    }
  }

  function toggleSidebar(mode){
    if( sidebar === true && mode === activeSidebar ){
      updateSettings({ sidebar: false, lastMessageCheck: new Date().toLocaleString() });
      return;
    }

    const msgTime = mode === 'chat' ? new Date().toLocaleString() : lastMessageCheck;
    updateSettings({ sidebar: true, activeSidebar: mode, lastMessageCheck: msgTime });
  }


  function togglePDF(e){
    if( activeTool === 'pdf' ){
      updateSettings({ activeTool: null });
    }else{
      updateSettings({ activeTool: "pdf" });
    }
  }


  const actualTools = tools?.filter(t => t.type !== 'EmbeddedNode' );
  const disableArTools = !(onSite.length && AREnabled);
  
  return (
    <nav id="bottomBar" className="navbar navbar-dark bg-dark py-0 pr-0 flex-nowrap d-md-none">  
      <div className="navbar-tools mx-auto">
        <NavAction label="Draw" icon='fa-draw-polygon' onClick={toggleDraw} active={activeTool === 'draw'} tour="drawing" disabled={disableArTools} panel />
        <NavAction label="Screenshot" icon='fa-camera' onClick={toggleScreenshot} active={activeTool === 'screenshot'} tour="screenshot" disabled={disableArTools} panel  />
        <div className={`btn-group ${onSite && 'pl-md-5'}`}>
          <NavAction label="Assets" icon='fa-archive' active={activeSidebar === 'assets' && sidebar === true} onClick={_ => toggleSidebar('assets')}  tour="assets" panel />
          { activeDocument && <NavAction label="Document Session" icon='fa-file-pdf' active={activeTool === 'pdf'} onClick={togglePDF} /> }
          <NavAction 
            label="Chat" 
            useTip={false} 
            icon='fa-comments' 
            active={activeSidebar === 'chat' && sidebar === true} 
            onClick={_ => toggleSidebar('chat')} tour="chat"
            badge={ (activeSidebar === 'chat' && sidebar === true) ? null : (newMessages > 0 ? newMessages : null) }
          />
          <NavAction 
            label="Tools" 
            useTip={false} 
            icon='fa-tools' 
            active={activeSidebar === 'tools' && sidebar === true} 
            onClick={_ => toggleSidebar('tools')}
            tour="tools" 
            badge={actualTools?.length > 0 ? actualTools?.length : null } 
          />
        </div>
        <NavAction label="Status Pane" useTip={false} icon="fa-hard-hat" active={activeSidebar === 'status' && sidebar === true} onClick={_ => toggleSidebar('status')} tour="statusPane" />
        <button className="btn btn-dark py-3 px-3 px-xl-4 toolbar-button" ref={inviteBtn} onClick={e => setShowInvite(!showInvite)} title="Invite">
            <i className="fa fa-user-plus fa-fw"></i>
        </button>
        <Overlay show={showInvite} target={inviteBtn} transition={false} placement="top" onHide={e => setShowInvite(false)} rootClose={true}>
        {(props) => (
          <Popover {...{ id: 'invitePopover', ...props }}>
            <Popover.Title as="h6"><i className="fa fa-fw fa-user-plus mr-1" style={{ fontSize: '.8em' }}></i> Invite a Member</Popover.Title>
            <Popover.Content>
              <Invite room={roomUUID} close={e => setShowInvite(false)} />
            </Popover.Content>
          </Popover>
        )}
        </Overlay>
      </div>
    </nav>
  )
}

export default BottomBar;