import React, { useEffect, useState } from 'react';
import { useRoom, useAssets, useSession } from '@turnaroundfactor/react';
import { ADAPT_CONFIG } from '@turnaroundfactor/common';
import Uploads from './Uploads'
import Asset from './Asset';
import uploader from '../../utils/uploader';

const icons = {
  cad: 'fa-cubes',
  document: 'fa-file-pdf',
  media: 'fa-image'
};

const extensions = Object.keys(ADAPT_CONFIG.FILE_TYPES).map(k => `.${k}`);

export default function Assets({ open }){
  const [filters, setFilters] = useState(['document', 'media', 'cad']);
  const [mode, setMode] = useState('list');
  const { uuid: roomId } = useRoom();
  const { isLoading, isFetching, data, refetch } = useAssets(roomId, { refetchOnFocus: false, pollingInterval: 5000 });
  const { user } = useSession();

  function toggleFilter(name){
    if ( filters.includes(name) ){
      setFilters(filters.filter(f => f !== name));
    } else {
      setFilters([...filters, name]);
    }
  }

  function attachFiles(event){
    const files = [...event.target.files];
    files.forEach(f => {
      uploader.add({
        file: f,
        mimeType: f.type,
        size: f.size,
        filename: f.name,
        roomId,
        userId: user.uuid
      })
    });
  }

  const ToggleButton = ({ name }) => {
    const active = filters.includes(name);
  
    return (
      <button type="button" onClick={e => toggleFilter(name)} className={`btn btn-sm btn-light ${active ? 'text-dark' : 'text-muted'}`}>
        <i className={`far fa-fw mr-1 ${active ? 'fa-check-square' : 'fa-square'}`}></i>
        <i className={`fa ${icons[name]}`}></i>
      </button>
    )
  }

  useEffect(() => {
    const reload = () =>{
      try{
        refetch();
      }catch(e){}
    }
    uploader.on('UPDATE', reload);
    return _ => uploader.off('UPDATE', reload);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) refetch();
  }, [open, refetch]);

  return (
    <section id='assets' className="d-flex flex-column p-4">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="font-weight-bold">
          Assets { (isLoading || isFetching) && <small><i className="fa fa-spinner fa-spin ml-2"></i></small> }
        </h5>
        <label id="assetInput" className="btn" role="button">
          <i className="fa fa-fw fa-plus-circle"></i>
          <input type="file" accept={extensions.join(",")} onChange={attachFiles} multiple />
        </label>
      </div>
      <p className="small mb-0">Choose a file to open it. Drag and drop files to this panel to upload them.</p>
      <div className="asset-filters btn-toolbar align-items-center border-bottom pb-2 mb-3 pt-3" role="toolbar" aria-label="File filters">
        <div className="btn-group align-items-center mr-2" role="group">
          <button type="button" className="btn btn-sm btn-light py-2 d-inline-flex" onClick={e => setMode(mode === 'grid' ? 'list' : 'grid')}>
            <i className={`fa fa-fw ${mode === 'list' ? 'fa-list' : 'fa-grip-horizontal'}`}></i>
            <small className="asset-filter-label ml-2">{mode}</small>
          </button>
          
        </div>
        <div className="btn-group align-items-center ml-auto" role="group">
          <ToggleButton name="media" />
          <ToggleButton name="document" />
          <ToggleButton name="cad" />
        </div>
      </div>
      <Uploads />
      {(isLoading || !data) && <span>Loading <i className="fa fa-fw fa-spinner fa-spin"></i></span>}
      <ul id="assetList" className={`list-unstyled ${mode}`}>
        { data?.filter(a => filters.includes(a.type)).map((asset, i) => <Asset key={i} asset={asset} refetch={refetch} />)}
        {(!data || (data && data.length <= 0)) && 
        <li className="py-2">
          <span className="asset-filename font-italic text-muted">No assets found</span>
        </li>}
      </ul>
    </section>
  )
}