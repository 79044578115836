/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import leftClick from '../../assets/mouse_left_click.png';
import mouseMove from '../../assets/mouse_move.png';
import mouseWheel from '../../assets/mouse_wheel.png';
import escapeKey from '../../assets/key_esc.png';
import key1 from '../../assets/key_1.png';
import key2 from '../../assets/key_2.png';
import keyUp from '../../assets/key_up.png';
import keyDown from '../../assets/key_down.png';
import keyLeft from '../../assets/key_left.png';
import keyRight from '../../assets/key_right.png';
import keyW from '../../assets/key_w.png';
import keyA from '../../assets/key_a.png';
import keyS from '../../assets/key_s.png';
import keyD from '../../assets/key_d.png';


function Item({ image, divider = "", children }){
  const images = Array.isArray(image) ? image : [image];

  return (
    <li className="py-1">
      <div className="d-flex align-items-center border bg-light">
        <div className="d-flex col-md-2 justify-content-center pl-0 align-items-center">
          {images.map((i, x) => <img key={x} src={i} />).reduce((prev, curr) => [prev, divider, curr])}
        </div>
        <div className='p-2 pl-3 bg-white border-left flex-grow-1 content'>
          {children}
        </div>
      </div>
    </li>
  )
}

function UnityHelp(){
  const [open, setOpen] = useState(true);

  const icon = open === true ? 'fa-times' : 'fa-question';

  return (
    <div id="unity_help" className={`w-100 ${open === true ? 'h-100' : '' } flex-column justify-content-center align-items-center bg-white`}>
      { open === true && 
      <div id="unity_help_inner" className={`pt-4 pb-5 w-100`}>
          <h3 className="border-bottom pb-2 mb-4">Unity Player Help</h3>
          <h5>Start and Stop Controlling the 3D Scene</h5>
          <ul className="list-unstyled">
            <Item image={leftClick}>
              Hover over the 3D scene with your mouse and ’Left Click’ to take control, this will hide your mouse. <br />
              By default, this will activate Orbit Mode controls.
            </Item>
            <Item image={[escapeKey, leftClick]} divider="&amp;">
              When you want to stop controlling the 3D scene and show your mouse again, press the ‘Escape’ key to unhide your mouse. 
              The 3D scene will continue to follow your mouse, ‘Left Click’ outside of the player area to stop this.
            </Item>
          </ul>

          <h5>Change Control Modes</h5>
          <ul className="list-unstyled">
            <Item image={key1}>
              While controlling the 3D scene, press the ‘1’ key to activate Orbit Mode controls. 
              This mode is already enabled by default, and pressing ‘1’ will only cause a change if you changed to a different 3D scene control mode.
            </Item>
            <Item image={key2}>
              While controlling the 3D scene, press the ‘2’ key to activate Free Fly Mode controls. This is not enabled by default.
            </Item>
          </ul>

          <h5>Orbit Control Mode</h5>
          <ul className="list-unstyled">
            <Item image={key1}>
              For easy control and big picture perspective, Orbit Mode is enabled by default. 
              This mode has a camera that uses your mouse to spin around the 3D scene - like a satellite that would always face the earth.
            </Item>
            <Item image={mouseMove}>
              While controlling the 3D scene, moving your mouse will spin and move the camera around the 3D scene. 
              To stop this, you must press ‘Escape’ and then ‘Left Click’ outside of the Unity Web Player, on another part of the page.
            </Item>
            <Item image={mouseWheel}>
              While controlling the 3D scene, using the ‘Scroll Wheel’ on your mouse will zoom the camera in and out, 
              closer and further away from the 3D scene. There is a limit to how close you can get to the scene to prevent the camera from moving inside of 3D objects.
            </Item>
          </ul>

          <h5>Free Fly Control Mode</h5>
          <ul className="list-unstyled">
            <Item image={key2}>
              For less convenient but more specific control, Free Fly Mode is disabled by default but can be activated with the ‘2’ key. 
              This mode has a camera that can fly through the 3D scene with your mouse controlling orientation and keys controlling position and strafing.
            </Item>
            <Item image={mouseMove}>
              While controlling the 3D scene, moving your mouse will rotate the direction the camera is facing. 
              To stop this, you must press ‘Escape’ and then ‘Left Click’ outside of the Unity Web Player, on another part of the website.
            </Item>
            <Item image={[keyUp, keyW]} divider="or">
              While controlling the 3D scene, using the ‘Up Arrow’ or ‘W’ key will move the camera forward in the direction the camera is facing.
            </Item>
            <Item image={[keyLeft, keyA]} divider="or">
              While controlling the 3D scene, using the ‘Left Arrow’ or ‘A’ key will move the camera to the left, based on the direction the camera is facing.
            </Item>
            <Item image={[keyRight, keyS]} divider="or">
              While controlling the 3D scene, using the Right Arrow’ or ‘S’ key will move the camera to the right, based on the direction the camera is facing.
            </Item>
            <Item image={[keyDown, keyD]} divider="or">
              While controlling the 3D scene, using the Down Arrow’ or ‘D’ key will move the camera backwards, based on the direction the camera is facing.
            </Item>
          </ul>
      </div>
      }
      <button type="button" className="btn btn-dark btn-32 p-0 rounded-circle closer" onClick={_ => setOpen(!open)}>
        <i className={`fa ${icon} fa-fw`}></i>
      </button>
    </div>
  )
}

export default UnityHelp;