import React, { useEffect } from 'react'
import { useSettings } from '@turnaroundfactor/react'
import DrawScreen from './Draw'
import ScreenshotView from './Screenshot'

function ARPanel({ children, tool }) {
  const { settings, update: updateSettings } = useSettings();
  const { activeTool } = settings;

  function close(event){
    event?.preventDefault();
    if( ['screenshot', 'draw'].includes(activeTool) && tool !== activeTool ) {
      updateSettings({ activeTool: null });
    }
  }

  useEffect(() => {
    return _ => close();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`screen-tool d-flex flex-column ${tool}-tool`}>
      { children }
    </div>
  )
}

function Draw() {
  return (
    <ARPanel tool="draw">
      <DrawScreen />
    </ARPanel>
  )
}

function Screenshot() {
  return (
    <ARPanel tool="screenshot">
      <ScreenshotView />
    </ARPanel>
  )
}

export { Draw, Screenshot }