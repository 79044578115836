import { setupStore } from '@turnaroundfactor/react';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
  uploading: false
}

export const uploads = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    upload: (state, { payload }) => {
      state.files.push(payload);
    },

    update: (state, { payload }) => {
      const file = state.files.find(f => f.uid === payload.uid);
      const keep = state.files.filter(f => f.uid !== payload.uid);

      if ( file ){
        state.files = [...keep, { ...file, ...payload }];
      }
    },

    complete: (state, { payload }) => {
      state.files = state.files.filter(f => f.uid !== payload.uid);
    },

    clear: (state, { payload }) => {
      state.files = [];
    }
  }
});

const store = setupStore({
  uploads: uploads.reducer
});


export const { 
  upload: uploadFile, 
  update: updateFile, 
  complete: uploadComplete,
  clear: clearUploads
} = uploads.actions;

export default store;