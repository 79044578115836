import { useRoom } from '@turnaroundfactor/react';
import React from 'react';

import UnityHelp from './UnityHelp';
import UnityPlayer from './UnityPlayer';

export default function UnityMain(props){
  const { users } = useRoom();

  const onSite = users.filter(m => m.type === 'OnSiteUser' && m.state === 'InRoom');

  if ( onSite && onSite.some(u => u.connected) ) {
    return <UnityPlayer peer={onSite[0]} />
  }

  return (
    <div id='unity_main' className="d-flex mh-100 bg-dark">
      <div id="unity_wrap" className="d-flex justify-content-center align-items-center flex-column">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <i className="fa fa-spinner fa-spin fa-2x text-muted fa-fw" />
          <span className="d-block pt-2 text-muted">Waiting for on-site user.</span>
        </div>
      </div>
      <UnityHelp />
    </div>
  )
}