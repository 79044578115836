import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { assetService } from '@turnaroundfactor/common';
import { useSettings } from '@turnaroundfactor/react';
import { getMediaType } from '../features/assets/helpers';

export default function MediaView() {
  const { settings, update: updateSettings } = useSettings();
  const { activeMedia } = settings;

  const [mediaURL, setMediaURL] = useState(null);

  async function loadMedia() {
    const { uuid } = activeMedia;
    try {
      const { data } = await axios.get(assetService('fetch'), { params: { uuid }});
      setMediaURL(data.url);
    } catch(e) {}
  }

  useEffect(() => {
    activeMedia ? loadMedia() : setMediaURL(null); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMedia]);

  const mtype = activeMedia ? getMediaType(activeMedia) : null;
  const { filename } = activeMedia || {};

  return (
    <Modal show={!!activeMedia} onHide={e => updateSettings({ activeMedia: null })} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '1rem'}}>{filename}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { mediaURL === null ? <div className="text-center"><i className="fa fa-spinner fa-spin fa-fw"></i></div>  : <>
        { mtype === 'image' ? <div className="text-center">
          <img src={mediaURL} alt={filename} className="mw-100 mx-auto" style={{ maxWidth: '100%' }} data-testid="image" />
        </div> : 
        <figure className="embed-responsive embed-responsive-16by9 video w-100">
          <video width="1920" height="1080" controls className="embed-responsive-item" src={mediaURL} data-testid="video"></video>
        </figure>
        }
        </>}
      </Modal.Body>
    </Modal>
  )
}