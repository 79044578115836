import adapt from '@turnaroundfactor/common'
import { useRoom, useSession, useSettings } from '@turnaroundfactor/react'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popover, Overlay } from "react-bootstrap";
import logo from '../assets/logo_white.svg'
import { leaveRoom } from '../utils/audio'
import getMessagesSinceTime from '../utils/getMessagesSinceTime'
import IconText from './IconText'
import Recorder from './Recorder'
import UserMenu from './UserMenu'
import Invite from './EmailInvite'
import JoinDetail from './JoinDetail'
import NavAction from './NavAction'


function Toolbar(){
  const { user } = useSession();
  const { settings, update: updateSettings } = useSettings();
  const { 
    activeTool, 
    AREnabled, 
    ARFeeds,
    activeDocument, 
    activeSidebar, 
    sidebar, 
    lastMessageCheck, 
    room 
  } = settings;

  const { members, tools, uuid: roomUUID, name: roomName, messages } = useRoom();

  const navigate = useNavigate();
  const [newMessages, setNewMessages] = useState(getMessagesSinceTime(lastMessageCheck, messages));
  const onSite = members?.filter(m => m.state === 'InRoom' && m.type === 'OnSiteUser');

  const inviteBtn = useRef(null);
  const detailBtn = useRef(null);

  const [showInvite, setShowInvite] = useState(false);
  const [showJoinDetail, setShowJoinDetail] = useState(false);

  useEffect(() => {
    setNewMessages(getMessagesSinceTime(lastMessageCheck, messages));
  }, [messages, lastMessageCheck, activeSidebar]);

  useEffect(() => {
    if ( ARFeeds.length === 0 || AREnabled === false ){
      if( ['screenshot', 'draw'].includes(activeTool) ) {
        updateSettings({ activeTool: null });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ARFeeds, updateSettings, AREnabled]);
   

  function disconnect(){
    leaveRoom.play();
    adapt.disconnect();

    updateSettings({ 
      room: null, 
      activeTool: null,
      activeDocument: null,
      activeMedia: null,
      sidebar: false,
      prevRoom: null,
      pinned: null
    });
    
    navigate('/');
  }

  function toggleDraw(event){
    if( activeTool === 'draw' ){
      updateSettings({ activeTool: null });
    }else{
      updateSettings({ activeTool: "draw" });
    }
  }

  function toggleScreenshot(){
    if( activeTool === 'screenshot' ) {
      updateSettings({ activeTool: null });
    }else{
      updateSettings({ activeTool: "screenshot" });
    }
  }

  function toggleSidebar(mode){
    if( sidebar === true && mode === activeSidebar ){
      updateSettings({ sidebar: false, lastMessageCheck: new Date().toLocaleString() });
      return;
    }

    const msgTime = mode === 'chat' ? new Date().toLocaleString() : lastMessageCheck;
    updateSettings({ sidebar: true, activeSidebar: mode, lastMessageCheck: msgTime });
  }


  function togglePDF(e){
    if( activeTool === 'pdf' ){
      updateSettings({ activeTool: null });
    }else{
      updateSettings({ activeTool: "pdf" });
    }
  }


  const actualTools = tools?.filter(t => t.type !== 'EmbeddedNode' );
  const disableArTools = !(ARFeeds.length && AREnabled);
  
  return (
    <React.Fragment>
      <nav id="toolbar" className="navbar navbar-dark bg-dark py-0 pr-0 flex-nowrap">
        <div className="navbar-brand">
          <img src={logo} className="d-block" alt="SME Connect" />
        </div>

        { roomUUID && <>
            <div className="d-none d-md-flex ml-auto navbar-tools">
              <NavAction label="Draw" icon='fa-draw-polygon' onClick={toggleDraw} active={activeTool === 'draw'} tour="drawing" disabled={disableArTools} panel />
              <NavAction label="Screenshot" icon='fa-camera' onClick={toggleScreenshot} active={activeTool === 'screenshot'} tour="screenshot" disabled={disableArTools} panel  />
              <div className={`btn-group ${onSite && 'pl-md-5'}`}>
                <NavAction label="Assets" icon='fa-archive' active={activeSidebar === 'assets' && sidebar === true} onClick={_ => toggleSidebar('assets')}  tour="assets" panel />
                { activeDocument && <NavAction label="Document Session" icon='fa-file-pdf' active={activeTool === 'pdf'} onClick={togglePDF} /> }
                <NavAction 
                  label="Chat" 
                  useTip={false} 
                  icon='fa-comments' 
                  active={activeSidebar === 'chat' && sidebar === true} 
                  onClick={_ => toggleSidebar('chat')} tour="chat"
                  badge={ (activeSidebar === 'chat' && sidebar === true) ? null : (newMessages > 0 ? newMessages : null) }
                />
                <NavAction 
                  label="Tools" 
                  useTip={false} 
                  icon='fa-tools' 
                  active={activeSidebar === 'tools' && sidebar === true} 
                  onClick={_ => toggleSidebar('tools')}
                  tour="tools" 
                  badge={actualTools?.length > 0 ? actualTools?.length : null } 
                />
                <Recorder />
              </div>
            </div>
            { roomName && roomName !== "" && 
            <button className="p-0 bg-transparent border-0 navbar-text d-flex align-items-center ml-auto mr-4 toolbar-button" id="room_desc" data-tour="roomInfo" ref={detailBtn} onClick={e => setShowJoinDetail(!showJoinDetail) }>
              <span className="badge badge-light py-1 rounded-left">Room</span>
              <span className="badge badge-secondary room-name pl-2 py-1 rounded-right text-nowrap text-truncate d-block" title={roomName}>{roomName}</span>
            </button> }
            <Overlay show={showJoinDetail} target={detailBtn} transition={false} placement="bottom" onHide={e => setShowJoinDetail(false)} rootClose={true}>
            {(props) => (
              <Popover {...{ id: 'joinDetailPopover', ...props }}>
                <Popover.Title as="h6"><i className="fa fa-fw fa-info-circle mr-1" style={{ fontSize: '.8em' }}></i> Room Detail</Popover.Title>
                <Popover.Content>
                  <JoinDetail />
                </Popover.Content>
              </Popover>
            )}
            </Overlay>
            <div className="d-none d-md-flex">
              <NavAction label="Status Pane" useTip={false} icon="fa-users" active={activeSidebar === 'status' && sidebar === true} onClick={_ => toggleSidebar('status')} tour="statusPane" />
              <button className={`btn ${showInvite ? 'btn-light bg-white' : 'btn-dark'} py-3 px-3 px-xl-4 toolbar-button`} ref={inviteBtn} onClick={e => setShowInvite(!showInvite)} title="Invite">
                  <i className="fa fa-user-plus fa-fw"></i>
              </button>
              <Overlay show={showInvite} target={inviteBtn} transition={false} placement="bottom-end" onHide={e => setShowInvite(false)} rootClose={true} data-tour="invite">
              {(props) => (
                <Popover {...{ id: 'invitePopover', ...props }}>
                  <Popover.Title as="h6"><i className="fa fa-fw fa-user-plus mr-1" style={{ fontSize: '.8em' }}></i> Invite a Member</Popover.Title>
                  <Popover.Content>
                    <Invite room={roomUUID} close={e => setShowInvite(false)} />
                  </Popover.Content>
                </Popover>
              )}
              </Overlay>
            </div>
        </>}
        <div className="btn-group">
          { roomUUID && 
          <button className={`btn btn-dark py-3 px-3 px-xl-4 toolbar-button border-right-0`} onClick={disconnect} data-tour="disconnect">
            <IconText icon='fa-sign-out-alt' label="Disconnect" />
          </button> }
          <UserMenu user={user} room={room} />
        </div>

      </nav>
    </React.Fragment>
  )
}

export default Toolbar;