import logo from '../assets/logo_mark.svg'

export default function Preload(){
  return (
    <div id='preload'>
      <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <img src={logo} width={80} alt="SME Connect" className="mb-4" data-testid="logo" />
          <i className="fa fa-spinner fa-spin fa-fw fa-2x"></i>
      </div>
    </div>
  )
}