import { useSettings, useRoom, useSession } from '@turnaroundfactor/react';
import adapt from '@turnaroundfactor/common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import store from '../../data/store';
import Avatar from '../../components/Avatar';
import logger from '../../utils/logging';
import updateARState from '../../utils/updateARState';

function PeerUser({ name, type, uuid }){
  
  const vidRef     = useRef(null);
  const node       = useRef(null);

  const { broadcast, users } = useRoom();

  const peer = useMemo(_ => {
    return users.find(m => m.uuid === uuid);
  } , [uuid, users]);

  const videoID = useMemo(_ => `peerUser-${uuid}`, [uuid]);
  
  const [connected, setConnected] = useState(peer.connected);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [connectionDegraded, setConnectionDegraded] = useState(false);  
  const [screen, setScreen] = useState(null);
  const { user: currentUser } = useSession();

  const { settings, update: updateSettings } = useSettings();
  const { 
    pinned
  } = settings;

  function kickPeer(event) {
    event?.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if ( confirm(`Kick ${name}?`) ){
      if ( adapt.room ) adapt.room.kick({ uuid });
    }
  }

  function mutePeer(type = 'audio') {
    broadcast('MUTE', { uuid, type, source: { name: currentUser.name, uuid: currentUser.id } });
  }

  function sendLocalMediaState() {
    const { app } = store.getState();
    const { audioEnabled, videoEnabled } = app;

    console.log(`Sending local audio and video status to ${peer.name} [audio: ${audioEnabled} | video: ${videoEnabled}]`);

    peer.send('user_event', { 
      type: 'LOCAL_STATE', 
      payload: { 
        audio: audioEnabled,
        video: videoEnabled
      } 
    });
  }

  function teardownVideo(player){
    const stream = player.srcObject;
    if( stream && stream.getTracks ){
      stream.getTracks().forEach(t => {
        t.stop()
      });
      
      player.pause();
      player.srcObject = null;
    }
  }

  useEffect(()=>{
    const player = vidRef.current;

    function setupPlayer(){
      player.muted = false;
      player.volume = 0.5;
    }

    if( player ) setupPlayer();
  }, [vidRef]);

  useEffect(()=>{
    const player = vidRef.current;
    return () => teardownVideo(player);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  useEffect(()=>{

    let rendered = true;

    logger.info(`Peer added: ${peer.name}`);

    if ( peer.connected ) sendLocalMediaState();
    
    peer.on('CONNECTED',  _ => {
      setConnected(true);
      setConnectionDegraded(false);

      logger.info(`Peer Connected to webUI: ${peer.name}`);
      setTimeout(sendLocalMediaState, 1000);
      setTimeout(sendLocalMediaState, 3000);

    });

    peer.on('DISCONNECT', _ => {
      
      if( rendered === true ){
        setConnectionDegraded(false);
        setConnected(false);
      }

      if( vidRef.current && vidRef.current.srcObject ){
        logger.info(`Disconnected from ${peer.name}. Tearing down video.`);
        teardownVideo(vidRef.current);
      }
    });

    function trackHandler(result){
      const { track } = result;
      logger.debug(`Received ${track.kind} media track from ${peer.name}`);
      
      const player = vidRef.current;
      const src    = player.srcObject;

      if ( !track ) return;

      if ( src  ){
        src.getTracks().forEach(t => {
          if ( t.readyState === 'ended' ) src.removeTrack(t);
        });

        src.addTrack(track);
      } else {
        player.srcObject = new MediaStream([track]);
      }
      
    }

    peer.on('TRACK', trackHandler);

    if( peer.getTracks().length >= 1 ){
      vidRef.current.srcObject = new MediaStream(peer.getTracks());
    }

    peer.on('CONNECTION_DEGRADED', _ => {
      logger.debug(`Connection to ${peer.name} degraded.`);
      if( rendered === true ) setConnectionDegraded(true);
    });

    if ( type === 'OnSiteUser' ) {
      // iPad features
      peer.on('DISCONNECT', _ => updateARState(uuid, false));
      peer.on('CHANGE_SCREEN', s => setScreen(s));
    }
    

    peer.on('CONNECTION_ACTIVE',   _ => setConnectionDegraded(false));
    peer.on('LOCAL_STATE', ({ audio, video }) => {
      console.log(`Received local state from ${peer.name}`, { audio, video });
      setVideoEnabled(video);
      setAudioEnabled(audio);
    });

    peer.on('SPEAKING', (data) => {
      const div = node.current;      
      if( data.speaking && audioEnabled === true ){
        div?.classList.add('-speaking');
      }else{
        div?.classList.remove('-speaking');
      }
    });

    return _ => {
      updateARState(uuid, false);
      rendered = false;
      peer.off();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    setConnected(peer.connected);
  }, [peer.connected]);

  const ratio = "16by9";
  const isPinned = pinned === peer.uuid;

  return (
    <div className={`peer-user ${videoEnabled === false ? '-muted' : ''} ${connected === true ? '-connected' : ''}`} ref={node} data-type={type}>
      <figure className={` video`} data-testid="video" data-peer-id={uuid}>
        <video id={videoID} autoPlay playsInline ref={vidRef}></video>
        <figcaption>
          {name} 
          {screen && <><br />viewing: ({screen})</>}
        </figcaption>
      </figure>
      { videoEnabled === false && 
        <div data-testid="avatar" className="peer-avatar d-flex flex-column align-items-center">
          <Avatar user={{ name }} size={75} />
        </div> 
      }
      <Dropdown className="peer-options">
        <Dropdown.Toggle variant="link text-white">
          <i className="fas fa-fw fa-ellipsis-v"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="rounded">
          <Dropdown.Header className="pb-0">{name}</Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item as="button" disabled={audioEnabled === false} onClick={e => mutePeer('audio')}>Mute Audio</Dropdown.Item>
          <Dropdown.Item as="button" disabled={videoEnabled === false} onClick={e => mutePeer('video')}>Mute Video</Dropdown.Item>
          <Dropdown.Item as="button" onClick={e => updateSettings({ pinned: (isPinned ? null : peer.uuid) })}>{ isPinned ? 'Un-Pin' : 'Pin User' }</Dropdown.Item>
          <Dropdown.Item as="button" onClick={kickPeer}>Kick</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className="peer-status-indicators d-flex align-items-center">
        { audioEnabled === false &&  <i data-testid="audioIcon" className="fa fa-fw fa-microphone-slash text-danger rounded-circle" /> }
        { connectionDegraded === true &&  <i data-testid="connectionIcon" className="fa fa-fw fa-plug text-danger rounded-circle" /> }
      </div>
      { connected === false &&
        <div className="peer-indicator text-center" data-testid="spinner">
          <i className="fa fa-spin fa-spinner fa-lg fa-fw" />
        </div>
      }
    </div>
  );
}

export default React.memo(PeerUser);