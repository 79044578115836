import { useTool } from '@turnaroundfactor/react';
import React, { useEffect, useRef } from 'react';
import Tool from '../Tool';

function Caliper({ peer }){
  const vidRef = useRef(null);

  const { status, data = 0.01 } = useTool(peer, {
    name: "Digital Caliper",
    useVideo: true
  });

  useEffect(()=>{
    function trackHandler(result){
      const { track } = result;

      if( track ){
        if( vidRef.current.srcObject ){
          vidRef.current.srcObject.addTrack(track);
        }else{
          vidRef.current.srcObject = new MediaStream([track]);
        }
      }
    }

    peer.on('TRACK', trackHandler);
    return _ => {
      peer.off();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <Tool title={peer.name} uuid={peer.uuid}>
      { status !== 'connected' && (
        <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100 position-absolute connect text-center" style={{ zIndex: 10 }}>
          <i className="fa fa-spinner fa-spin fa-2x text-dark"></i>
          <span className="text-dark font-italic d-block text-center small mt-1">Connecting...</span>
        </div>
      )}
      <figure className="embed-responsive embed-responsive-16by9 video">
        <video ref={vidRef} autoPlay playsInline muted></video>
      </figure>
      <span className="d-block p-1 text-center h5 m-0 text-monospace">{data?.formatted} {data?.unit}</span>
    </Tool>
  )
}

export default Caliper;
