const cadExtensions = [
  "pxz",
  "3ds",
  "sat",
  "sab",
  "dwg",
  "dxf",
  "fbx",
  "ipt",
  "iam",
  "rvt",
  "rfa",
  "model",
  "session",
  "catpart",
  "catproduct",
  "catshape",
  "cgr",
  "3dxml",
  "dae",
  "asm",
  "neu",
  "prt",
  "xas",
  "xpr",
  "csb",
  "gltf",
  "glb",
  "ifc",
  "igs",
  "iges",
  "jt",
  "obj",
  "prt",
  "x_b",
  "x_t",
  "p_t",
  "p_b",
  "xmt",
  "xmt_txt",
  "xmt_bin",
  "plmxml",
  "e57",
  "pts",
  "ptx",
  "prc",
  "3dm",
  "rvm",
  "skp",
  "asm",
  "par",
  "pwd",
  "psm",
  "sldasm",
  "sldprt",
  "stp",
  "step",
  "stpz",
  "stepz",
  "stl",
  "u3d",
  "usdz",
  "usda",
  "usdc",
  "vda",
  "wrl",
  "vrml"
];

const icons = {
  close: "data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMzMzMzMzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgODUuODEyIDg1LjgxMiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgODUuODEyIDg1LjgxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTcwLjY5LDE0LjEyMWMtMTUuNjIxLTE1LjYyLTQwLjk0NS0xNS42Mi01Ni41NjgsMGMtMTUuNjIxLDE1LjYyLTE1LjYyLDQwLjk0NiwwLDU2LjU2NyAgIGMxNS42MjQsMTUuNjIzLDQwLjk0OSwxNS42MjMsNTYuNTY5LDAuMDAyQzg2LjMxMiw1NS4wNzEsODYuMzEzLDI5Ljc0NCw3MC42OSwxNC4xMjF6IE01NC44NzEsNTkuMjlMNDIuNDA2LDQ2LjgyNUwyOS45NDIsNTkuMjkgICBjLTEuMjIzLDEuMjIzLTMuMjAyLDEuMjIyLTQuNDIyLDAuMDAyYy0xLjIyMy0xLjIyNC0xLjIyMS0zLjIsMC00LjQyMmwxMi40NjYtMTIuNDY2TDI1LjUyMiwyOS45NDEgICBjLTEuMjIzLTEuMjIzLTEuMjIzLTMuMjAxLDAtNC40MjFjMS4yMTktMS4yMjIsMy4xOTktMS4yMjMsNC40MiwwbDEyLjQ2NSwxMi40NjRMNTQuODcsMjUuNTJjMS4yMjItMS4yMjIsMy4xOTktMS4yMjIsNC40MiwwLjAwMSAgIGMxLjIyMSwxLjIxOSwxLjIyMiwzLjE5OCwwLjAwMSw0LjQxOUw0Ni44MjYsNDIuNDA0bDEyLjQ2NSwxMi40NjZjMS4yMjIsMS4yMjIsMS4yMjIsMy4yMDEsMCw0LjQyMlM1Ni4wOTMsNjAuNTEyLDU0Ljg3MSw1OS4yOXoiPjwvcGF0aD48L2c+PC9zdmc+",
  sendToDevice: "data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMzMzMzMzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgeD0iMHB4IiB5PSIwcHgiPjx0aXRsZT5zZW5kPC90aXRsZT48ZyBkYXRhLW5hbWU9IkxheWVyIDEyNiI+PHBhdGggZD0iTTIzLDI2YTEsMSwwLDAsMS0uNTMtLjE1TDEyLjcsMTkuNjhBMSwxLDAsMCwxLDEyLjY0LDE4bDUuMjQtMy44NEwxMC42NCwxNy44YTEsMSwwLDAsMS0xLDBMMy40NywxMy44NWExLDEsMCwwLDEtLjQ2LTFBMSwxLDAsMCwxLDMuNzcsMTJsMjUtNmExLDEsMCwwLDEsMSwuMjksMSwxLDAsMCwxLC4yMSwxbC02LDE4YTEsMSwwLDAsMS0uNjEuNjJBMSwxLDAsMCwxLDIzLDI2Wm0tOC03LjIzLDcuNDUsNC43TDI2Ljk0LDEwWk02LjU0LDEzLjQybDMuNzEsMi4zNEwyMi42Nyw5LjU1WiI+PC9wYXRoPjxwYXRoIGQ9Ik0xMiwyNmExLDEsMCwwLDEtMS0uNzZsLTItOGExLDEsMCwwLDEsLjU0LTEuMTRMOS43NiwxNmwxNy43OS04LjlhMSwxLDAsMCwxLDEuMy4zNywxLDEsMCwwLDEtLjI2LDEuMzNMMTMuOTIsMTkuNTYsMTMsMjUuMTZBMSwxLDAsMCwxLDEyLDI2Wm0tLjgzLTguNDYuNjMsMi41NUwxMiwxOC44NGExLDEsMCwwLDEsLjQtLjY0bDUuNDctNFoiPjwvcGF0aD48cGF0aCBkPSJNMTIsMjZhMSwxLDAsMCwxLS41MS0xLjg2bDUtM2ExLDEsMCwwLDEsMSwxLjcybC01LDNBMSwxLDAsMCwxLDEyLDI2WiI+PC9wYXRoPjwvZz48L3N2Zz4="
}

const logLevels = {
  'error' : 'Error',
  'warn': 'Warning',
  'info': 'Info',
  'debug': 'Debug'
}


export { cadExtensions, icons, logLevels }