import { useEffect, useRef } from 'react'

export default function DrawScreen({ peerId, image, view = 'video' }) {
  const vid = useRef(null);

  useEffect(() => {
    if ( peerId && view === 'video' ){
      const main = document.getElementById(`peerUser-${peerId}`);
      if ( main && main.srcObject ){
        const stream = main.srcObject.clone();
        vid.current.srcObject = stream;
      }
    }
  }, [peerId, view]);

  return (
    <>
    { view === 'draw' && 
      <>
        <div className="position-relative mb-auto draw-mode_image">
          { image && <img src={`data:image/png;base64,${image}`} alt="Screenshot from HL2" id="draw_image_ref" className="w-100" /> }
        </div>
      </>
    }
    { view === 'video' && <video id={`drawVideo-${peerId}`} ref={vid} autoPlay playsInline muted className="w-100 draw-mode_video" /> }
    </>
  )
}