import { useSettings } from '@turnaroundfactor/react';
import React from 'react';
import Chat from '../chat';
import Tools from '../toolbox';
import Assets from '../assets';
import StatusPane from './StatusPane';

function Sidebar(){
  const { settings } = useSettings();
  const { activeSidebar, sidebar } = settings;

  return (
    <div id="right" className={`sidebar-${(activeSidebar && sidebar) ? activeSidebar : 'closed'}`}>
      <div className={`${activeSidebar === 'assets' ? 'd-block' : 'd-none'} h-100 sidebar-panel`}>
        <Assets open={activeSidebar === 'assets' && sidebar === true} />
      </div>
      <div className={`${activeSidebar === 'tools' ? 'd-block' : 'd-none'} h-100 sidebar-panel`}>
        <Tools />
      </div>
      <div className={`${activeSidebar === 'chat' ? 'd-block' : 'd-none'} h-100 sidebar-panel`}>
        <Chat open={activeSidebar === 'chat' && sidebar} />
      </div>
      <div className={`${activeSidebar === 'status' ? 'd-block' : 'd-none'} h-100 sidebar-panel`}>
        <StatusPane />
      </div>
    </div>
  )
}

export default Sidebar;