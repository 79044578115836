import { presenceService } from "@turnaroundfactor/common";
import axios from 'axios';

export function getInRoomUsers(members) {
  if ( !members ) {
    return [];
  }
  
  return members.filter(m => {
    return (m.type === 'RemoteExpert' || m.type === 'OnSiteUser') && m.state === 'InRoom';
  })
}

export async function getRoomByUUID(uuid) {
  try{
    const { data } = await axios.get(presenceService('rooms_get'), { params: { uuid } });
    const room = Array.isArray(data) ? data[0] : data;
    const members = room.members?.filter(m => m.type === 'RemoteExpert' && m.state === 'InRoom');
    return { room, members };
   }catch(e){
     return null;
   }
}

export async function getRecentRooms(days) {
  try {
    const { data } = await axios.get(presenceService('rooms_get'), { 
      params: {
        modified_after: new Date(Date.now() - days * 24 * 60 * 60 * 1000).getTime()
      }
    });

    return data
  } catch(e) {
    return [];
  }
}