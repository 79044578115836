import React, { useCallback, useRef, useEffect } from 'react'
import { useSettings } from '@turnaroundfactor/react';
import UnityMain from '../unity'
import VideoGrid from './VideoGrid'
import IconText from '../../components/IconText'


function ToggleButton({ tour, mode, onClick, ...iconProps }){
  return (
    <button className={`btn ${mode === 'video' ? 'btn-dark' : 'btn-light'} p-0 rounded-circle mx-1 ${mode}`} onClick={onClick} title={ iconProps.label } data-toggle="tooltip" data-tour={tour}>
      <IconText {...iconProps} />
    </button>
  )
}

function MediaButton({ tour, active, onClick, ...iconProps }){
  return (
    <button className={`btn ${active ? 'btn-danger' : 'btn-light'} p-0 rounded-circle mx-1`} onClick={onClick} title={ iconProps.label } data-toggle="tooltip" data-tour={tour}>
      <IconText {...iconProps} />
    </button>
  )
}

export default function CallScreen({ compact }){
  const node = useRef(null);
  const mode = useRef('video');
  const { settings, update: updateSettings } = useSettings();
  const { audioEnabled, videoEnabled } = settings;

  const setMode = useCallback((m, apply = true) => { 
    node.current.setAttribute('data-primary', m);
    node.current.setAttribute('data-compact', compact);

    if ( apply ) {
      mode.current = m;
      document.querySelectorAll('#video_toggle button').forEach(n => {
        n.classList.remove('btn-dark');
        n.classList.add('btn-light');
      });

      document.querySelectorAll(`#video_toggle button.${m}`).forEach(n => {
        n.classList.remove('btn-light');
        n.classList.add('btn-dark');
      });
    }
  }, [compact]);

  useEffect(_ => {
    setMode(compact ? 'video' : mode.current, false);
  }, [compact, setMode]);

  return (
    <section id='call_screen' data-primary={compact ? 'video' : mode} data-compact={compact} ref={node}>
      <VideoGrid />
      <UnityMain />
      <div id="video_toggle" className="d-flex flex-row align-items-center justify-content-center">
        <div className="mr-2 me-2">
          <MediaButton onClick={ e => updateSettings({ audioEnabled: !audioEnabled })} active={!audioEnabled} icon={audioEnabled ? 'fa-microphone' : 'fa-microphone-slash'} label="Toggle audio" tour="toggleAudio" />
          <MediaButton onClick={ e => updateSettings({ videoEnabled: !videoEnabled })} active={!videoEnabled} icon={videoEnabled ? 'fa-video' : 'fa-video-slash'} label="Toggle video" tour="toggleVideo" />
        </div>
        <div className="ml-2 ms-2">
          <ToggleButton onClick={ e => setMode("video") } mode="video" icon="fa-user-friends" label="Focus Video" tour='focusVideo' />
          <ToggleButton onClick={ e => setMode("unity")} mode="unity" icon="fa-unity" label="Focus Unity" tour="focusUnity" brand />
          <ToggleButton onClick={ e => setMode("split") } mode="split" icon="fa-sort" label="Split View" tour="focusSplitView" />
        </div>
      </div>
    </section>
  );
}
