import { enterRoom, roomRequest } from '../../utils/audio';

export function enterRoomSound(){
  try{ 
    enterRoom.play();
  } catch(e) {}
}

export function roomRequestSound() {
  try{
    roomRequest.play();
  } catch(e) {}
}